import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { Group, GroupReq, GroupUserRequest, ModuleGroupRequest, UUID, User, UserGroupRequest } from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private _httpClient: HttpClientService) {}

  getGroups(params?: any): Observable<any> {
    return this._httpClient.authGet2('groups', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postCreateGroup(params: GroupReq): Observable<any> {
    return this._httpClient.authPost('groups/add', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postCreateUpdateGroup(params: { group: GroupReq, action: string }): Observable<any> {
    return this._httpClient.authPost(`groups/${params.action}`, params.group).pipe(
      map((response: any) => {
        if (response.status && (response.code === 200 || response.code === 201)) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postCreateUserGroups(params: GroupUserRequest): Observable<any> {
    return this._httpClient.authPost('/groups/users/add', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postUpdateGroup(params: GroupReq): Observable<any> {
    return this._httpClient.authPost('groups/update', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postGroupToUsersRemoval(params: GroupUserRequest): Observable<any> {
    return this._httpClient.authPost('groups/users/remove', params);
  }

  getGroupUsers(params: UUID): Observable<User[]> {
    return this._httpClient.authGet2('groups/users/show-group-users', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data[0].users;
        }
        return throwError(() => response);
      }),
    );
  }

  getGroupMenus(params: UUID): Observable<any[]> {
    return this._httpClient.authGet2('groups/menus/show-group-menus', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.data;
        }
        return throwError(() => response);
      }),
    );
  }
}
