import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { MenuGroupRequest, MenuRequest, UUID } from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private _httpClient: HttpClientService) {}

  getMenus(params?: any): Observable<any> {
    return this._httpClient.authGet2('menus', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getMenu(params: UUID): Observable<any> {
    return this._httpClient.authGet2('menus/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getMenuGroups(params: UUID): Observable<any> {
    return this._httpClient.authGet2('menus/show-menu-group', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuCreation(params: MenuRequest): Observable<any> {
    return this._httpClient.authPost('menus/create', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuUpdateCreation(params: { menu: MenuRequest, action: string }): Observable<any> {
    return this._httpClient.authPost(`menus/${params.action}`, params.menu).pipe(
      map((response: any) => {
        if (response.status && (response.code === 200 || response.code === 201)) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuGroupCreation(params: MenuGroupRequest): Observable<any> {
    return this._httpClient.authPost('menus/groups/add', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuGroupDeletion(params: MenuGroupRequest): Observable<any> {
    return this._httpClient.authPost('menus/groups/remove', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuDeletion(params: UUID): Observable<any> {
    return this._httpClient.authPost('menus/remove', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postMenuUpdate(params: MenuRequest): Observable<any> {
    return this._httpClient.authPost('menus/update', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }
}
