import {
    Address,
    Bank,
    DocumentPath,
    Email,
    Employer,
    NextOfKin,
    Phone,
    CommentDetails,
  } from '.';
  
  export interface Merchant {
    id: string;
    first_name?: string;
    last_name: string;
    company_name: string;
    company_email: string;
    email: string;
    email_verified_at: Date;
    cac: string;
    logo: string;
    activated: string;
    user_customer_no: string;
    is_live: string;
    created_at: Date;
    updated_at: Date;
    Company_code: string;
  }
  
  export interface MerchantListParams {
    uuid?: string;
    page?: number;
    per_page?: number;
  }
  
  export interface MerchantResponseData {
    current_page: number;
    data: Merchant[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: any[];
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
  }
  
  export interface MerchantUpdateResponseData {
    current_page: number;
    data: MerchantUpdate;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: any[];
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
  }
  
  export interface MerchantCountryReqParams {
    country?: string;
    state?: string;
  }
  
  export interface MerchantCountryRespData {
    CountryID: string;
    Country: string;
    CountryCode: string;
  }
  
  export interface MerchantUpdate {
    PersonMasterDraftID?: string;
    PersonMasterID?: string;
    Title?: string;
    Orig_Title?: string;
    FirstName?: string;
    Orig_FirstName?: string;
    MiddleName?: string;
    Orig_MiddleName?: string;
    LastName?: string;
    Orig_LastName?: string;
    DateofBirth?: string;
    Orig_DateofBirth?: string;
    NIN?: string;
    Orig_NIN?: string;
    BVN?: string;
    Orig_BVN?: string;
    Gender?: string;
    Orig_Gender?: string;
    Nationality?: string;
    Orig_Nationality?: string;
    CountryofOrigin?: string;
    Orig_CountryofOrigin?: string;
    StateofOrigin?: string;
    Orig_StateofOrigin?: string;
    BankDetailJson?: Bank[];
    Orig_BankDetail?: Bank[];
    ContactAddressJson?: Address[];
    Orig_ContactAddressJson?: Address[];
    EmailAddressJson?: Email[];
    Orig_EmailAddressJson?: Email[];
    PhoneNumberJson?: Phone[];
    Orig_PhoneNumberJson?: Phone[];
    NOKFirstName?: string;
    Orig_NOKFirstName?: string;
    NOKLastName?: string;
    Orig_NOKLastName?: string;
    RelationshipType?: string;
    Orig_RelationshipType?: string;
    Comment?: CommentDetails[];
    NOKPhoneNumber?: string;
    NOKEmailAddress?: string;
    MerchantTypeName?: string;
    Orig_MerchantTypeName?: string;
    AccountManagerNumber?: string;
    DocumentNote?: string;
    Orig_DocumentNote?: any;
    DocumentPath?: DocumentPath[];
    Orig_DocumentPath?: DocumentPath[];
    DocumentUID?: string;
    Orig_DocumentUID?: string;
    MaritalStatus?: string;
    Orig_MaritalStatus?: string;
    MotherMaidenName?: string;
    Orig_MotherMaidenName?: string;
    MerchantNumber?: string;
    EmployerDetails?: Employer;
    Orig_EmployerDetails?: Employer;
    NextofKinDetails?: NextOfKin;
    Orig_NextofKinDetails?: NextOfKin;
    FundSource?: any;
    Orig_FundSource?: any;
    AnnualIncome?: any;
    Orig_AnnualIncome?: any;
    RiskTolerance?: any;
    Orig_RiskTolerance?: any;
    InvestmentExperience?: any;
    Orig_InvestmentExperience?: any;
    InvestmentObjective?: any;
    Orig_InvestmentObjective?: any;
    PersonMasterDraftReference?: string;
    BVNCheckResult?: any;
    NINCheckResult?: any;
    IsSubmitted?: string;
    AccountStatus?: string;
    RejectionReason?: string[];
    IsRejected?: string;
  }
  
  