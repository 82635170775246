import { createSelector } from '@ngrx/store';
import { MerchantData } from '.';
import { AppState } from '../root-state';

const getMerchantState = (state: AppState): MerchantData => state.merchant;

export const getMerchantLoading = createSelector(
  getMerchantState,
  state => state.isLoading,
);

export const getMerchantError = createSelector(getMerchantState, state => state.error);

export const getApprovedMerchants = createSelector(
  getMerchantState,
  state => state.approvedmerchants,
);
export const getPendingMerchants = createSelector(
  getMerchantState,
  state => state.pendingmerchants,
);
export const getInDraftMerchants = createSelector(
  getMerchantState,
  state => state.indraftmerchants,
);
export const getMerchant = createSelector(
  getMerchantState,
  state => state.selectedmerchant,
);
export const getMerchantRejectionReasons = createSelector(
  getMerchantState,
  state => state.rejectionReasons,
);
export const getMerchantAccountStatus = createSelector(
  getMerchantState,
  state => state.accountStatus,
);
