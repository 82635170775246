import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MenuService } from 'app/core/service/menu.service';
import { Observable, switchMap, map, catchError, of } from 'rxjs';
import * as menuActions from './menu.actions';

@Injectable()
export class MenuEffects {
  constructor(private _actions$: Actions, private _menuService: MenuService) {}

  loadMenu$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<menuActions.LoadMenus>(
        menuActions.MenuActionTypes.LoadMenus,
      ),
      switchMap(() =>
        this._menuService.getMenus().pipe(
          map(items => new menuActions.LoadMenusSuccess(items)),
          catchError(error =>
            of(new menuActions.LoadMenusFail(error)),
          ),
        ),
      ),
    ),
  );
}
