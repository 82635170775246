/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    // subtitle: 'Here we go, To the MOON 🐱‍🏍🐱‍🏍🐱‍🏍 😁',
    subtitle: '',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'dashboards.customer',
        title: 'Customers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/customer',
      },
      {
        id: 'dashboards.user',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/user',
      },
      {
        id: 'dashboards.module',
        title: 'Modules',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/module',
      },
      {
        id: 'dashboards.group',
        title: 'Groups',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/group',
      },
      {
        id: 'dashboards.section',
        title: 'Section',
        type: 'basic',
        icon: 'heroicons_outline:color-swatch',
        link: '/section',
      },
      {
        id: 'dashboards.menu',
        title: 'Menu',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/menu',
      },
      {
        id: 'dashboards.token',
        title: 'Access Token',
        type: 'basic',
        icon: 'heroicons_outline:key',
        link: '/token',
      },
      {
        id: 'dashboards.officer',
        title: 'Account Officers',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/officer',
      },
      {
        id: 'dashboards.merchant',
        title: 'Merchants',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/merchant',
      },
      {
        id: 'dashboards.transaction',
        title: 'Transactions',
        type: 'basic',
        icon: 'heroicons_outline:document-report',
        link: '/transaction',
      },
      {
        id: 'dashboards.bils',
        title: 'Bills Payment Usage',
        type: 'basic',
        icon: 'heroicons_outline:tag',
        link: '/bills',
      },
      {
        id: 'dashboards.trades',
        title: 'Trades',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/trades',
      },
      {
        id: 'dashboards.orange',
        title: 'Orange One',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/orange-one',
      },
    ],
  },
  {
    id: 'divider-1',
    type: 'divider',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
];
