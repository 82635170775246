import { MenuActions, MenuActionTypes } from './menu.actions';
import { initialMenuState, MenuData } from './menu.state';

export const menuReducer = (state = initialMenuState, action: MenuActions): MenuData => {
  switch (action.type) {
    case MenuActionTypes.LoadMenus: {
      return { ...state, isLoading: true, error: null };
    }

    case MenuActionTypes.LoadMenusSuccess: {
      return { ...state, menus: action.payload, isLoading: false, error: null };
    }

    case MenuActionTypes.LoadMenusFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MenuActionTypes.LoadMenu: {
      return { ...state, menu: action.payload };
    }

    case MenuActionTypes.ClearMenu: {
      return { ...state, menus: null, menu: null };
    }

    default: {
      return state;
    }
  }
}
