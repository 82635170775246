import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, switchMap, map, catchError, of } from 'rxjs';
import * as userActions from './user.actions';
import { UserService } from 'app/core/service/user.service';

@Injectable()
export class UserEffects {
  constructor(private _actions$: Actions, private _userService: UserService) {}

  loadUsers$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<userActions.LoadUsers>(
        userActions.UserActionTypes.LoadUsers,
      ),
      switchMap(() =>
        this._userService.getUsers().pipe(
          map(items => new userActions.LoadUsersSuccess(items)),
          catchError(error =>
            of(new userActions.LoadUsersFail(error)),
          ),
        ),
      ),
    ),
  );

  loadUser$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<userActions.LoadUser>(
        userActions.UserActionTypes.LoadUser,
      ),
      switchMap(action =>
        this._userService.getUser(action.payload).pipe(
          map(items => new userActions.LoadUserSuccess(items)),
          catchError(error =>
            of(new userActions.LoadUserFail(error)),
          ),
        ),
      ),
    ),
  );
}
