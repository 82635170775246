import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { NavigationListSuccess } from '../models/navigation';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private httpService: HttpClientService) {}

  getUserAccess(): Observable<NavigationListSuccess> {
    return this.httpService.authGet('/user/access');
  }
}
