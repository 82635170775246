import { GeneralActions, GeneralActionTypes } from './general.actions';
import { GeneralData, initialGeneralState } from './general.state';

export const generalReducer = (state = initialGeneralState, action: GeneralActions): GeneralData => {
  switch (action.type) {
    case GeneralActionTypes.LoadDocumentTypes: {
      return { ...state, isLoading: true, error: null };
    }

    case GeneralActionTypes.LoadDocumentTypesSuccess: {
      return { ...state, documenttypes: action.payload, isLoading: false, error: null };
    }

    case GeneralActionTypes.LoadDocumentTypesFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case GeneralActionTypes.LoadMaritalStatus: {
      return { ...state, isLoading: true, error: null };
    }

    case GeneralActionTypes.LoadMaritalStatusSuccess: {
      return { ...state, maritalstatus: action.payload, isLoading: false, error: null };
    }

    case GeneralActionTypes.LoadMaritalStatusFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case GeneralActionTypes.LoadGenders: {
      return { ...state, isLoading: true, error: null };
    }

    case GeneralActionTypes.LoadGendersSuccess: {
      return { ...state, gender: action.payload, isLoading: false, error: null };
    }

    case GeneralActionTypes.LoadGendersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case GeneralActionTypes.LoadCountries: {
      return { ...state, isLoading: true, error: null };
    }

    case GeneralActionTypes.LoadCountriesSuccess: {
      return { ...state, country: action.payload, isLoading: false, error: null };
    }

    case GeneralActionTypes.LoadCountriesFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case GeneralActionTypes.LoadBankList: {
      return { ...state, isLoading: true, error: null };
    }

    case GeneralActionTypes.LoadBankListSuccess: {
      return { ...state, banks: action.payload, isLoading: false, error: null };
    }

    case GeneralActionTypes.LoadBankListFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    default: {
      return state;
    }
  }
}
