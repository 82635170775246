import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { UUID, ModuleGroupRequest, ModuleRequest } from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  constructor(private _httpClient: HttpClientService) {}

  getModules(): Observable<any> {
    return this._httpClient.authGet2('modules').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getModule(params: UUID): Observable<any> {
    return this._httpClient.authGet2('modules/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getModuleGroups(params: UUID): Observable<any> {
    return this._httpClient.authGet2('modules/groups/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postModuleCreation(params: ModuleRequest): Observable<any> {
    return this._httpClient.authPostUploads('modules/create', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postModuleUpdateCreation(params: { module: ModuleRequest, action: string }): Observable<any> {
    return this._httpClient.authPostUploads(`modules/${params.action}`, params.module).pipe(
      map((response: any) => {
        if (response.status && (response.code === 200 || response.code === 201)) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postModuleGroupCreation(params: ModuleGroupRequest): Observable<any> {
    return this._httpClient.authPostUploads('modules/groups/add', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postModuleGroupDeltion(params: ModuleGroupRequest): Observable<any> {
    return this._httpClient.authPostUploads('modules/groups/remove', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postModuleUpdate(params: ModuleRequest): Observable<any> {
    return this._httpClient.authPost('modules/update', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }
}
