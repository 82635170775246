import { createSelector } from '@ngrx/store';
import { AccountOfficerData } from '.';
import { AppState } from '../root-state';

const getAccountOfficerState = (state: AppState): AccountOfficerData => state.accountofficer;

export const getAccountOfficerLoading = createSelector(
  getAccountOfficerState,
  state => state.isLoading,
);

export const getAccountOfficerError = createSelector(getAccountOfficerState, state => state.error);

export const getApprovedAccountOfficers = createSelector(
  getAccountOfficerState,
  state => state.accountofficers,
);
