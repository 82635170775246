import { AccountOfficerEffects } from './accountofficer';
import { CustomerEffects } from './customer-store';
import { GeneralEffects } from './general-store';
import { GroupEffects } from './group-store';
import { UserEffects } from './user-store';
import { ModuleEffects } from './module-store';
import { SectionEffects } from './section-store';
import { MenuEffects } from './menu-store';
import { NavigationEffects } from './navigation';

export const appEffects = [
  CustomerEffects,
  GeneralEffects,
  AccountOfficerEffects,
  UserEffects,
  GroupEffects,
  ModuleEffects,
  SectionEffects,
  MenuEffects,
  NavigationEffects,
];
