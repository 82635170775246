import { ModuleResp } from 'app/core/models';



export interface ModuleData {
  isLoading: boolean;
  error: Error;
  modules: any;
  module: ModuleResp;
}

export interface ModuleState {
  readonly module: ModuleData
}

export const initialModuleState: ModuleData = {
  isLoading: false,
  error: null,
  modules: null,
  module: null,
}
