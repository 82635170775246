import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { AccountOfficer2 } from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AccountOfficerService {

  constructor(private _httpClient: HttpClientService) {}

  getAccountOfficers(params?: any): Observable<any> {
    return this._httpClient.authGet2('account-officers', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getAccountOfficer(params: { id: string }): Observable<AccountOfficer2> {
    return this._httpClient.authGet2('account-officers/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postAccountOfficerCreation(params: FormData): Observable<any> {
    return this._httpClient.authPostUploads('account-officers/create-update', params);
  }
}
