import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MerchantService } from 'app/core/service/merchant.service';
import { Observable, startWith, switchMap, map, catchError, of } from 'rxjs';
import * as merchantActions from './merchant.actions';

@Injectable()
export class MerchantEffects {
  constructor(private _actions$: Actions, private _merchantService: MerchantService) {}

  loadApprovedMerchant$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadApprovedMerchants>(
        merchantActions.MerchantActionTypes.LoadApprovedMerchants,
      ),
      switchMap(action =>
        this._merchantService.getMerchants(action.payload).pipe(
          map(items => new merchantActions.LoadApprovedMerchantsSuccess(items.data)),
          catchError(error =>
            of(new merchantActions.LoadApprovedMerchantsFail(error)),
          ),
        ),
      ),
    ),
  );

  loadPendingMerchant$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadPendingMerchants>(
        merchantActions.MerchantActionTypes.LoadPendingMerchants,
      ),
      switchMap(action =>
        this._merchantService.getMerchants(action.payload).pipe(
          map(items => new merchantActions.LoadPendingMerchantsSuccess(items.data)),
          catchError(error =>
            of(new merchantActions.LoadPendingMerchantsFail(error)),
          ),
        ),
      ),
    ),
  );

  loadInDraftMerchant$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadInDraftMerchants>(
        merchantActions.MerchantActionTypes.LoadInDraftMerchants,
      ),
      switchMap(action =>
        this._merchantService.getMerchants(action.payload).pipe(
          map(items => new merchantActions.LoadInDraftMerchantsSuccess(items.data)),
          catchError(error =>
            of(new merchantActions.LoadInDraftMerchantsFail(error)),
          ),
        ),
      ),
    ),
  );

  loadMerchant$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadMerchant>(
        merchantActions.MerchantActionTypes.LoadMerchant,
      ),
      switchMap(action =>
        this._merchantService.getMerchants(action.payload).pipe(
          map(items => new merchantActions.LoadMerchantSuccess(items.data)),
          catchError(error =>
            of(new merchantActions.LoadMerchantFail(error)),
          ),
        ),
      ),
    ),
  );

  loadMerchantRejectionReasons$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadMerchantRejectionReasons>(
        merchantActions.MerchantActionTypes.LoadMerchantRejectionReasons,
      ),
      switchMap(() =>
        this._merchantService.getRejectionResons().pipe(
          map(items => new merchantActions.LoadMerchantRejectionReasonsSuccess(items)),
          catchError(error =>
            of(new merchantActions.LoadMerchantRejectionReasonsFail(error)),
          ),
        ),
      ),
    ),
  );

  loadMerchantAccountStatus$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<merchantActions.LoadMerchantAccountStatus>(
        merchantActions.MerchantActionTypes.LoadMerchantAccountStatus,
      ),
      switchMap(() =>
        this._merchantService.getAccountStatus().pipe(
          map(items => new merchantActions.LoadMerchantAccountStatusSuccess(items)),
          catchError(error =>
            of(new merchantActions.LoadMerchantAccountStatusFail(error)),
          ),
        ),
      ),
    ),
  );
}
