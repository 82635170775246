import { GroupActions, GroupActionTypes } from './group.actions';
import { GroupData, initialGroupState } from './group.state';

export const groupReducer = (state = initialGroupState, action: GroupActions): GroupData => {
  switch (action.type) {
    case GroupActionTypes.LoadGroups: {
      return { ...state, isLoading: true, error: null };
    }

    case GroupActionTypes.LoadGroupsSuccess: {
      return { ...state, groups: action.payload, isLoading: false, error: null };
    }

    case GroupActionTypes.LoadGroupsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case GroupActionTypes.LoadGroup: {
      return { ...state, group: action.payload };
    }

    case GroupActionTypes.ClearGroup: {
      return { ...state, groups: null, group: null };
    }

    default: {
      return state;
    }
  }
}
