import { ActionReducerMap } from '@ngrx/store';
import { accountOfficerReducer } from './accountofficer';
import { customerReducer } from './customer-store';
import { generalReducer } from './general-store';
import { groupReducer } from './group-store';
import { AppState } from './root-state';
import { userReducer } from './user-store';
import { moduleReducer } from './module-store';
import { sectionReducer } from './section-store';
import { menuReducer } from './menu-store';
import { navigationReducer } from './navigation';
import { merchantReducer } from './merchant-store';

export const appReducers: ActionReducerMap<AppState, any> = {
  customer: customerReducer,
  general: generalReducer,
  accountofficer: accountOfficerReducer,
  user: userReducer,
  group: groupReducer,
  module: moduleReducer,
  section: sectionReducer,
  menu: menuReducer,
  navigation: navigationReducer,
  merchant: merchantReducer,
};
