import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    canActivate: [NoAuthGuard],
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      {
        path: 'sign-in',
        loadChildren: () =>
          import('./modules/auth/sign-in/sign-in.module').then((m) => m.SignInModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./modules/auth/forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule,
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('./modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule,
          ),
      },
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule,
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule,
          ),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'customer',
        loadChildren: () =>
          import('app/modules/customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'module',
        loadChildren: () =>
          import('app/modules/module/module.module').then((m) => m.ModuleModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('app/modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('app/modules/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'group',
        loadChildren: () =>
          import('app/modules/group/group.module').then((m) => m.GroupModule),
      },
      {
        path: 'section',
        loadChildren: () =>
          import('app/modules/section/section.module').then((m) => m.SectionModule),
      },
      {
        path: 'menu',
        loadChildren: () =>
          import('app/modules/menu/menu.module').then((m) => m.MenuModule),
      },
      {
        path: 'token',
        loadChildren: () =>
          import('app/modules/access-token/access-token.module').then(
            (m) => m.AccessTokenModule,
          ),
      },
      {
        path: 'officer',
        loadChildren: () =>
          import('app/modules/account-officer/account-officer.module').then(
            (m) => m.AccountOfficerModule,
          ),
      },
      {
        path: 'merchant',
        loadChildren: () =>
          import('app/modules/merchant/merchant.module').then((m) => m.MerchantModule),
      },
      {
        path: 'transaction',
        loadChildren: () =>
          import('app/modules/transaction/transaction.module').then((m) => m.TransactionModule),
      },
    ],
  },
  { path: '**', redirectTo: 'sign-in', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
