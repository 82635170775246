import { CustomerActions, CustomerActionTypes } from './customer.actions';
import { CustomerData, initialCustomerState } from './customer.state';

export const customerReducer = (state = initialCustomerState, action: CustomerActions): CustomerData => {
  switch (action.type) {
    case CustomerActionTypes.LoadApprovedCustomers: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadApprovedCustomersSuccess: {
      return { ...state, approvedcustomers: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadApprovedCustomersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.LoadPendingCustomers: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadPendingCustomersSuccess: {
      return { ...state, pendingcustomers: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadPendingCustomersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.LoadInDraftCustomers: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadInDraftCustomersSuccess: {
      return { ...state, indraftcustomers: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadInDraftCustomersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.LoadCustomer: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadCustomerSuccess: {
      return { ...state, selectedcustomer: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadCustomerFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.LoadCustomerRejectionReasons: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadCustomerRejectionReasonsSuccess: {
      return { ...state, rejectionReasons: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadCustomerRejectionReasonsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.LoadCustomerAccountStatus: {
      return { ...state, isLoading: true, error: null };
    }

    case CustomerActionTypes.LoadCustomerAccountStatusSuccess: {
      return { ...state, accountStatus: action.payload, isLoading: false, error: null };
    }

    case CustomerActionTypes.LoadCustomerAccountStatusFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case CustomerActionTypes.ClearCustomer: {
      return { ...state, selectedcustomer: null };
    }

    default: {
      return state;
    }
  }
}
