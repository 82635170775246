import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CustomerService } from 'app/core/service/customer.service';
import { Observable, startWith, switchMap, map, catchError, of } from 'rxjs';
import * as customerActions from './customer.actions';

@Injectable()
export class CustomerEffects {
  constructor(private _actions$: Actions, private _customerService: CustomerService) {}

  loadApprovedCustomer$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadApprovedCustomers>(
        customerActions.CustomerActionTypes.LoadApprovedCustomers,
      ),
      switchMap(action =>
        this._customerService.getCustomers(action.payload).pipe(
          map(items => new customerActions.LoadApprovedCustomersSuccess(items.data)),
          catchError(error =>
            of(new customerActions.LoadApprovedCustomersFail(error)),
          ),
        ),
      ),
    ),
  );

  loadPendingCustomer$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadPendingCustomers>(
        customerActions.CustomerActionTypes.LoadPendingCustomers,
      ),
      switchMap(action =>
        this._customerService.getCustomers(action.payload).pipe(
          map(items => new customerActions.LoadPendingCustomersSuccess(items.data)),
          catchError(error =>
            of(new customerActions.LoadPendingCustomersFail(error)),
          ),
        ),
      ),
    ),
  );

  loadInDraftCustomer$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadInDraftCustomers>(
        customerActions.CustomerActionTypes.LoadInDraftCustomers,
      ),
      switchMap(action =>
        this._customerService.getCustomers(action.payload).pipe(
          map(items => new customerActions.LoadInDraftCustomersSuccess(items.data)),
          catchError(error =>
            of(new customerActions.LoadInDraftCustomersFail(error)),
          ),
        ),
      ),
    ),
  );

  loadCustomer$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadCustomer>(
        customerActions.CustomerActionTypes.LoadCustomer,
      ),
      switchMap(action =>
        this._customerService.getCustomers(action.payload).pipe(
          map(items => new customerActions.LoadCustomerSuccess(items.data)),
          catchError(error =>
            of(new customerActions.LoadCustomerFail(error)),
          ),
        ),
      ),
    ),
  );

  loadCustomerRejectionReasons$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadCustomerRejectionReasons>(
        customerActions.CustomerActionTypes.LoadCustomerRejectionReasons,
      ),
      switchMap(() =>
        this._customerService.getRejectionResons().pipe(
          map(items => new customerActions.LoadCustomerRejectionReasonsSuccess(items)),
          catchError(error =>
            of(new customerActions.LoadCustomerRejectionReasonsFail(error)),
          ),
        ),
      ),
    ),
  );

  loadCustomerAccountStatus$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<customerActions.LoadCustomerAccountStatus>(
        customerActions.CustomerActionTypes.LoadCustomerAccountStatus,
      ),
      switchMap(() =>
        this._customerService.getAccountStatus().pipe(
          map(items => new customerActions.LoadCustomerAccountStatusSuccess(items)),
          catchError(error =>
            of(new customerActions.LoadCustomerAccountStatusFail(error)),
          ),
        ),
      ),
    ),
  );
}
