import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError, switchMap, of, map } from 'rxjs';
import { UserService } from 'app/core/service/user.service';
import { AppStorage } from '../utils/storage';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClientService,
    private _userService: UserService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this._httpClient.guestPost('login', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        AppStorage.set('token', response.data.token);

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service & storage
        this._userService.user = response.data.user;
        AppStorage.set('currentUser', response.data.user);

        this.updateActivityTime();

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    let uuid = AppStorage.get('currentUser');

    uuid && this._userService.postUserSignOut({ uuid: uuid.UUID }).subscribe();

    // Remove the access token from the local storage
    AppStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Update last time user is active within the system
   */
  updateActivityTime(): void {
    AppStorage.set('lastActivity', new Date());
  }

  /**
   * Check if user is active within the sytem
   */
  lastActivityOkay(): boolean {
    let last = AppStorage.get('lastActivity');
    if (last) {
      last = new Date(last);
      const current = new Date();
      const diff = current.getTime() - last.getTime();
      const diffDays = Math.floor(diff / 86400000); // days
      const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes

      if (diffDays === 0 && diffHrs === 0 && diffMins < environment.activityCheck) {
        this._authenticated = true;
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Sign in using the access token
   */
  // Todo: Implement sign in using access token,

  /**
   * Forgot password
   *
   * @param email
  //  */
  // Todo: Forgot password

  /**
   * Reset password
   *
   * @param password
   */
  // Todo: Reset password

  /**
   * Sign up
   *
   * @param user
   */
  // Todo: Sign up

  /**
   * Unlock session
   *
   * @param credentials
   */
  // Todo: Unlock session

  /**
   * Check the authentication status
   */
  isLoggedIn(): boolean {
    if (!this._authenticated && !this.lastActivityOkay()) {
      return false;
    }
    return this._authenticated || this.lastActivityOkay();
  }

  check(): Observable<boolean> {
    // Check if the user is logged in & access token availability
    if (this.isLoggedIn && AppStorage.get('token')) {
      return of(true);
    }

    // Todo check for token expiration
    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //   return of(false);
    // }

    // Todo: Sign in using accestoken, when an api for it is available
    // If the access token exists and it didn't expire, sign in using it
    // return this.signInUsingToken();

    this.signOut();

    return of(false);

  }

  getTokens(params?: any): Observable<any> {
    return this._httpClient.authGet2('settings/access-tokens', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postRevokeTokens(params?: any): Observable<any> {
    return this._httpClient.authPost('settings/access-tokens/revoke', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postTokenCreation(params?: any): Observable<any> {
    return this._httpClient.authPost('settings/access-tokens/new', params, true).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }
}
