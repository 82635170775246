import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import {
  CountryReqParams,
  CustomerListParams,
  CustomerResponseData,
  DocumentTypes,
  DocumentTypesReq,
  Gender,
  BankList,
  MaritalStatus,
  CustomerUpdate,
} from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private _httpClient: HttpClientService) {}

  getCustomers(params: CustomerListParams): Observable<CustomerResponseData> {
    return this._httpClient.authGet2('customers', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getDocumentTypes(params: DocumentTypesReq): Observable<DocumentTypes[]> {
    return this._httpClient.authGet2('customers/document-types', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getGenders(): Observable<Gender[]> {
    return this._httpClient.authGet('customers/genders').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.map((item: string) => ({
            value: item,
            key: item.toLowerCase(),
          }));
        }
        return throwError(() => response);
      }),
    );
  }

  getMaritalStatus(): Observable<MaritalStatus[]> {
    return this._httpClient.authGet('customers/marital-status').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.map((item: string) => ({
            value: item,
            key: item.toLowerCase(),
          }));
        }
        return throwError(() => response);
      }),
    );
  }

  getCountryAndStates(params?: CountryReqParams): Observable<any> {
    return this._httpClient
      .authGet2('/customers/country-state-city', params && params)
      .pipe(
        map((response: any) => {
          if (response.status && response.code === 200) {
            return response.data;
          }
          return throwError(() => response);
        }),
      );
  }

  postCustomerCreationUpdate(params: FormData): Observable<any> {
    return this._httpClient.authPostUploads('customers/create-update', params);
  }

  postDraftApproval(params: { id: string; comment: string }): Observable<any> {
    return this._httpClient.authPost('customers/submit', params);
  }

  postCustomerApproval(params: { id: string, comment: string }): Observable<any> {
    return this._httpClient.authPost('customers/approve', params);
  }

  postDeleteDraft(params: { id: string }): Observable<any> {
    return this._httpClient.authPost('customers/delete', params);
  }

  postRejectDraft(params: { id: string, comment: string }): Observable<any> {
    return this._httpClient.authPost('customers/reject', params);
  }

  getReportServerUrl(): string {
    return this._httpClient.reportServer;
  }

  getUpdatedCustomerRecord(params: CustomerListParams): Observable<CustomerUpdate> {
    return this._httpClient.authGet2('customers/changes', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getBankList(): Observable<BankList[]> {
    return this._httpClient.authPhoenixApiGet('banks/list').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.banks;
        }
        return throwError(() => response);
      }),
    );
  }

  getRejectionResons(): Observable<string[]> {
    return this._httpClient.authGet('customers/reject-reasons').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );

  }

  getAccountStatus(): Observable<string[]> {
    return this._httpClient.authGet('customers/account-status').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postRejectInfoInDraft(params: { id: string, account_status: string, reasons: string[] }): Observable<any> {
    return this._httpClient.authPost('customers/cem-reject', params);
  }
}
