import { createSelector } from '@ngrx/store';
import { AppState } from '../root-state';
import { ModuleData } from '.';

const getModuleState = (state: AppState): ModuleData => state.module;

export const getModuleLoading = createSelector(
  getModuleState,
  state => state.isLoading,
);

export const getModuleError = createSelector(getModuleState, state => state.error);

export const getModules = createSelector(
  getModuleState,
  state => state.modules,
);

export const getModule = createSelector(
  getModuleState,
  state => state.module,
);
