import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { SectionGroupRequest, SectionRequest, UUID } from '../models';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor(private _httpClient: HttpClientService) {}

  getSections(params?: any): Observable<any> {
    return this._httpClient.authGet2('sections', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getSection(params: UUID): Observable<any> {
    return this._httpClient.authGet2('sections/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getSectionGroups(params: UUID): Observable<any> {
    return this._httpClient.authGet2('sections/show-section-group', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionCreation(params: SectionRequest): Observable<any> {
    return this._httpClient.authPost('sections/create', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionUpdateCreation(params: { section: SectionRequest, action: string }): Observable<any> {
    return this._httpClient.authPost(`sections/${params.action}`, params.section).pipe(
      map((response: any) => {
        if (response.status && (response.code === 200 || response.code === 201)) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionGroupCreation(params: SectionGroupRequest): Observable<any> {
    return this._httpClient.authPost('sections/groups/add', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionGroupDeletion(params: SectionGroupRequest): Observable<any> {
    return this._httpClient.authPost('sections/groups/remove', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionDeletion(params: UUID): Observable<any> {
    return this._httpClient.authPost('sections/remove', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }

  postSectionUpdate(params: SectionRequest): Observable<any> {
    return this._httpClient.authPost('sections/update', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 201) {
          return response;
        }
        return throwError(() => response);
      }),
    );
  }
}
