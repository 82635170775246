<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.avatar" [src]="user.avatar">
    <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <!-- <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button> -->
  <button mat-menu-item (click)="routeToSettings()">
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span>Settings</span>
  </button>
  <!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
    <span>Status</span>
  </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
