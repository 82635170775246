export interface ModuleRequest {
  name: string;
  description: string;
  sequence: string;
  active: string;
  uuid?: string;
  default_route?: string;
  icon_class?: string;
}

export interface ModuleResp {
  ID: string;
  ModuleID: string;
  Name: string;
  Description: string;
  DefaultRoute: string;
  Sequence: string;
  IconClass: string;
  Active: string;
  CreatedBy: string;
  UpdatedBy: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface ModuleGroupRequest {
  module_uuid: string;
  group_uuid: string[];
}

export interface UserGroupRequest {
  user_uuid: string[];
  group_uuid: string;
}

export interface GroupUserRequest {
  user_uuid: string;
  group_uuid: string[];
}

export interface UUID {
  uuid: string;
}

export interface GroupReq {
  name: string;
  description: string;
  uuid?: string
}

export interface Group {
  Active: string;
  CreatedBy?: string;
  DateCreated?: string;
  DateUpdated?: string;
  Description: string;
  GroupID: string;
  GroupName: string;
  ID: string;
  UpdatedBy?: string;
}
