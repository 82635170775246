import { Action } from '@ngrx/store';
import { Section } from 'app/core/models';

export enum SectionActionTypes {
  LoadSections = '[Sections] Load Sections',
  LoadSectionsSuccess = '[Sections] Load Sections Success',
  LoadSectionsFail = '[Sections] Load Sections Fail',

  LoadSection = '[Section] Load Section',

  ClearSection = '[Section] Clear Section',
}

export class LoadSections implements Action {
  readonly type = SectionActionTypes.LoadSections;
}

export class LoadSectionsSuccess implements Action {
  readonly type = SectionActionTypes.LoadSectionsSuccess;
  constructor(public payload: any) {}
}

export class LoadSectionsFail implements Action {
  readonly type = SectionActionTypes.LoadSectionsFail;
  constructor(public payload: any) {}
}

export class LoadSection implements Action {
  readonly type = SectionActionTypes.LoadSection;
  constructor(public payload: Section) {}
}

export class ClearSection implements Action {
  readonly type = SectionActionTypes.ClearSection;
}

export type SectionActions =
  | LoadSections
  | LoadSectionsSuccess
  | LoadSectionsFail
  | LoadSection
  | ClearSection;
