import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import {
  BankList,
  CountryReqParams,
  DocumentTypes,
  DocumentTypesReq,
  Gender,
  MaritalStatus,
  MerchantListParams,
  MerchantResponseData,
  MerchantUpdate,
} from '../models';
import { Observable, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private _httpClient: HttpClientService) {}

  getMerchants(params: MerchantListParams): Observable<MerchantResponseData> {
    return this._httpClient.authPost('taas/users', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getDocumentTypes(params: DocumentTypesReq): Observable<DocumentTypes[]> {
    return this._httpClient.authGet2('merchants/document-types', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getGenders(): Observable<Gender[]> {
    return this._httpClient.authGet('merchants/genders').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.map((item: string) => ({
            value: item,
            key: item.toLowerCase(),
          }));
        }
        return throwError(() => response);
      }),
    );
  }

  getMaritalStatus(): Observable<MaritalStatus[]> {
    return this._httpClient.authGet('merchants/marital-status').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.map((item: string) => ({
            value: item,
            key: item.toLowerCase(),
          }));
        }
        return throwError(() => response);
      }),
    );
  }

  getCountryAndStates(params?: CountryReqParams): Observable<any> {
    return this._httpClient
      .authGet2('/merchants/country-state-city', params && params)
      .pipe(
        map((response: any) => {
          if (response.status && response.code === 200) {
            return response.data;
          }
          return throwError(() => response);
        }),
      );
  }

  postMerchantCreationUpdate(params: FormData): Observable<any> {
    return this._httpClient.authPostUploads('merchants/create-update', params);
  }

  postDraftApproval(params: { id: string; comment: string }): Observable<any> {
    return this._httpClient.authPost('merchants/submit', params);
  }

  postMerchantApproval(params: { uuid: string; approve: number; reason: string; }): Observable<any> {
    return this._httpClient.authPost('taas/approve-reject', params);
  }

  postDeleteDraft(params: { id: string }): Observable<any> {
    return this._httpClient.authPost('merchants/delete', params);
  }

  postMerchantRejection(params: { uuid: string; approve: number; reason: string; }): Observable<any> {
    return this._httpClient.authPost('taas/approve-reject', params);
  }

  getReportServerUrl(): string {
    return this._httpClient.reportServer;
  }

  getUpdatedMerchantRecord(params: MerchantListParams): Observable<MerchantUpdate> {
    return this._httpClient.authGet2('merchants/changes', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getBankList(): Observable<BankList[]> {
    return this._httpClient.authPhoenixApiGet('banks/list').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data.banks;
        }
        return throwError(() => response);
      }),
    );
  }

  getRejectionResons(): Observable<string[]> {
    return this._httpClient.authGet('merchants/reject-reasons').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getAccountStatus(): Observable<string[]> {
    return this._httpClient.authGet('merchants/account-status').pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  postRejectInfoInDraft(params: {
    id: string;
    account_status: string;
    reasons: string[];
  }): Observable<any> {
    return this._httpClient.authPost('merchants/cem-reject', params);
  }
}
