<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-orange-700 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
      preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
      <g class="text-gray-100 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
        <circle r="220" cx="156" cy="220"></circle>
        <circle r="220" cx="790" cy="491"></circle>
      </g>
    </svg>
    <div class="flex items-center w-full p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <!-- Dark version -->
        <app-logo [type]="'dark'" [classes]="'w-16'"></app-logo>
      </div>
      <!-- Components -->
      <div class="flex items-center ml-auto">
        <!-- <notifications></notifications> -->
        <user [showAvatar]="false"></user>
      </div>
    </div>
    <!-- User -->
    <div *ngIf="user" class="flex flex-col items-center w-full p-4">
      <div class="relative w-20 h-20">
        <img class="w-full h-full rounded-full" *ngIf="user.avatar" [src]="user.avatar" alt="User avatar">
        <mat-icon class="icon-size-20" *ngIf="!user.avatar" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-6">
        <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-sm">
          {{user.FullName}}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-sm text-gray-100">
          {{user.Email}}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-15 mb-4 opacity-20">
      <app-logo [type]="'dark'" [classes]="'max-w-fit'"></app-logo>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <languages></languages> -->
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <!-- <shortcuts></shortcuts> -->
      <messages></messages>
      <!-- <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
      </button> -->
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
