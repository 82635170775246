import { MerchantActions, MerchantActionTypes } from './merchant.actions';
import { MerchantData, initialMerchantState } from './merchant.state';

export const merchantReducer = (state = initialMerchantState, action: MerchantActions): MerchantData => {
  switch (action.type) {
    case MerchantActionTypes.LoadApprovedMerchants: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadApprovedMerchantsSuccess: {
      return { ...state, approvedmerchants: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadApprovedMerchantsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.LoadPendingMerchants: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadPendingMerchantsSuccess: {
      return { ...state, pendingmerchants: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadPendingMerchantsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.LoadInDraftMerchants: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadInDraftMerchantsSuccess: {
      return { ...state, indraftmerchants: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadInDraftMerchantsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.LoadMerchant: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadMerchantSuccess: {
      return { ...state, selectedmerchant: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadMerchantFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.LoadMerchantRejectionReasons: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadMerchantRejectionReasonsSuccess: {
      return { ...state, rejectionReasons: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadMerchantRejectionReasonsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.LoadMerchantAccountStatus: {
      return { ...state, isLoading: true, error: null };
    }

    case MerchantActionTypes.LoadMerchantAccountStatusSuccess: {
      return { ...state, accountStatus: action.payload, isLoading: false, error: null };
    }

    case MerchantActionTypes.LoadMerchantAccountStatusFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case MerchantActionTypes.ClearMerchant: {
      return { ...state, selectedmerchant: null };
    }

    default: {
      return state;
    }
  }
}
