import { ModuleActions, ModuleActionTypes } from './module.actions';
import { initialModuleState, ModuleData } from './module.state';

export const moduleReducer = (state = initialModuleState, action: ModuleActions): ModuleData => {
  switch (action.type) {
    case ModuleActionTypes.LoadModules: {
      return { ...state, isLoading: true, error: null };
    }

    case ModuleActionTypes.LoadModulesSuccess: {
      return { ...state, modules: action.payload, isLoading: false, error: null };
    }

    case ModuleActionTypes.LoadModulesFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case ModuleActionTypes.LoadModule: {
      return { ...state, module: action.payload };
    }

    case ModuleActionTypes.ClearModule: {
      return { ...state, modules: null, module: null };
    }

    default: {
      return state;
    }
  }
}
