<ng-container>
  <div class="p-6">

  </div>
  <div class="overflow-x-auto mx-6">
    <table mat-table matSort [dataSource]="dataSource" [trackBy]="trackByFn" class="w-full bg-transparent">
      <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of tableColumns; let colIndex = index">

        <th class="border-b bg-gray-100 text-xs xl:text-sm lg:text-sm" mat-header-cell mat-sort-header *matHeaderCellDef>{{ column.header | titlecase }}</th>

        <td mat-cell *matCellDef="let element" class="text-xs xl:text-sm lg:text-sm">
          <span *ngIf="!column.isLink; else link"> {{ column.cell(element) }} </span>
          <ng-template #link>
            <a [routerLink]="[column.url]"> {{ column.cell(element) }} </a>
          </ng-template>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-xs xl:text-sm lg:text-sm text-center" [colSpan]="tableColumns.length">
          <span *ngIf="tableData.length === 0">Data is currently empty</span>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
