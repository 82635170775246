import { Action } from '@ngrx/store';
import { AccountOfficer2 } from 'app/core/models';

export enum AccountOfficerActionTypes {
  LoadAccountOfficers = '[Approved Account Officers] Load Approved Account Officers',
  LoadAccountOfficersSuccess = '[Approved Account Officers] Load Approved Account Officers Success',
  LoadAccountOfficersFail = '[Approved Account Officers] Load Approved Account Officers Fail',
}

export class LoadAccountOfficers implements Action {
  readonly type = AccountOfficerActionTypes.LoadAccountOfficers;
}

export class LoadAccountOfficersSuccess implements Action {
  readonly type = AccountOfficerActionTypes.LoadAccountOfficersSuccess;
  constructor(public payload: AccountOfficer2[]) {}
}

export class LoadAccountOfficersFail implements Action {
  readonly type = AccountOfficerActionTypes.LoadAccountOfficersFail;
  constructor(public payload: any) {}
}

export type AccountOfficerActions =
  | LoadAccountOfficers
  | LoadAccountOfficersSuccess
  | LoadAccountOfficersFail;
