// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseApiUrl: process.env['APP_BASE_API_URL'] || 'DEF_A',
    storekey: process.env['APP_STORE_KEY'] || 'DEF_A',
    reportServer: process.env['APP_REPORT_SERVER'] || 'DEF_A',
    sharedDrive: process.env['APP_SHARED_DRIVE'] || 'DEF_A',
    phoenixWebBaseApiUrl: process.env['PHOENIX_WEB_API'] || 'DEF_A',
    phoenixWebAccessKey: process.env['PHOENIX_WEB_ACCESS_KEY'] || 'DEF_A',
    phoenixWebAccessCode: process.env['PHOENIX_WEB_ACCESS_CODE'] || 'DEF_A',
    phoenixWebAppKey: process.env['PHOENIX_WEB_APP_KEY'] || 'DEF_A',
    activityCheck: 6,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
