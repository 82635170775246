import { Action } from '@ngrx/store';
import { Merchant, MerchantListParams } from 'app/core/models';

export enum MerchantActionTypes {
  LoadApprovedMerchants = '[Approved Merchants] Load Approved Merchants',
  LoadApprovedMerchantsSuccess = '[Approved Merchants] Load Approved Merchants Success',
  LoadApprovedMerchantsFail = '[Approved Merchants] Load Approved Merchants Fail',

  LoadPendingMerchants = '[Pending Merchants] Load Pending Merchants',
  LoadPendingMerchantsSuccess = '[Pending Merchants] Load Pending Merchants Success',
  LoadPendingMerchantsFail = '[Pending Merchants] Load Pending Merchants Fail',

  LoadInDraftMerchants = '[InDraft Merchants] Load InDraft Merchants',
  LoadInDraftMerchantsSuccess = '[InDraft Merchants] Load InDraft Merchants Success',
  LoadInDraftMerchantsFail = '[InDraft Merchants] Load InDraft Merchants Fail',

  LoadMerchant = '[Merchant] Load Merchant',
  LoadMerchantSuccess = '[Merchant] Load Merchant Success',
  LoadMerchantFail = '[Merchant] Load Merchant Fail',

  LoadMerchantRejectionReasons = '[Merchant Rejection Reasons] Load Merchant Rejection Reasons',
  LoadMerchantRejectionReasonsSuccess = '[Merchant Rejection Reasons] Load Merchant Rejection Reasons Success',
  LoadMerchantRejectionReasonsFail = '[Merchant Rejection Reasons] Load Merchant Rejection Reasons Fail',

  LoadMerchantAccountStatus = '[Merchant Account Status] Load Merchant Account Status',
  LoadMerchantAccountStatusSuccess = '[Merchant Account Status] Load Merchant Account Status Success',
  LoadMerchantAccountStatusFail = '[Merchant Account Status] Load Merchant Account Status Fail',

  ClearMerchant = '[Merchant] Clear Merchant',
}

export class LoadApprovedMerchants implements Action {
  readonly type = MerchantActionTypes.LoadApprovedMerchants;
  constructor(public payload: MerchantListParams) {}
}

export class LoadApprovedMerchantsSuccess implements Action {
  readonly type = MerchantActionTypes.LoadApprovedMerchantsSuccess;
  constructor(public payload: Merchant[]) {}
}

export class LoadApprovedMerchantsFail implements Action {
  readonly type = MerchantActionTypes.LoadApprovedMerchantsFail;
  constructor(public payload: any) {}
}

export class LoadPendingMerchants implements Action {
  readonly type = MerchantActionTypes.LoadPendingMerchants;
  constructor(public payload: MerchantListParams) {}
}

export class LoadPendingMerchantsSuccess implements Action {
  readonly type = MerchantActionTypes.LoadPendingMerchantsSuccess;
  constructor(public payload: Merchant[]) {}
}

export class LoadPendingMerchantsFail implements Action {
  readonly type = MerchantActionTypes.LoadPendingMerchantsFail;
  constructor(public payload: any) {}
}

export class LoadInDraftMerchants implements Action {
  readonly type = MerchantActionTypes.LoadInDraftMerchants;
  constructor(public payload: MerchantListParams) {}
}

export class LoadInDraftMerchantsSuccess implements Action {
  readonly type = MerchantActionTypes.LoadInDraftMerchantsSuccess;
  constructor(public payload: Merchant[]) {}
}

export class LoadInDraftMerchantsFail implements Action {
  readonly type = MerchantActionTypes.LoadInDraftMerchantsFail;
  constructor(public payload: any) {}
}

export class LoadMerchant implements Action {
  readonly type = MerchantActionTypes.LoadMerchant;
  constructor(public payload: MerchantListParams) {}
}

export class LoadMerchantSuccess implements Action {
  readonly type = MerchantActionTypes.LoadMerchantSuccess;
  constructor(public payload: Merchant[]) {}
}

export class LoadMerchantFail implements Action {
  readonly type = MerchantActionTypes.LoadMerchantFail;
  constructor(public payload: any) {}
}

export class LoadMerchantRejectionReasons implements Action {
  readonly type = MerchantActionTypes.LoadMerchantRejectionReasons;
}

export class LoadMerchantRejectionReasonsSuccess implements Action {
  readonly type = MerchantActionTypes.LoadMerchantRejectionReasonsSuccess;
  constructor(public payload: string[]) {}
}

export class LoadMerchantRejectionReasonsFail implements Action {
  readonly type = MerchantActionTypes.LoadMerchantRejectionReasonsFail;
  constructor(public payload: any) {}
}

export class LoadMerchantAccountStatus implements Action {
  readonly type = MerchantActionTypes.LoadMerchantAccountStatus;
}

export class LoadMerchantAccountStatusSuccess implements Action {
  readonly type = MerchantActionTypes.LoadMerchantAccountStatusSuccess;
  constructor(public payload: string[]) {}
}

export class LoadMerchantAccountStatusFail implements Action {
  readonly type = MerchantActionTypes.LoadMerchantAccountStatusFail;
  constructor(public payload: any) {}
}

export class ClearMerchant implements Action {
  readonly type = MerchantActionTypes.ClearMerchant;
}

export type MerchantActions =
  | LoadApprovedMerchants
  | LoadApprovedMerchantsSuccess
  | LoadApprovedMerchantsFail
  | LoadPendingMerchants
  | LoadPendingMerchantsSuccess
  | LoadPendingMerchantsFail
  | LoadInDraftMerchants
  | LoadInDraftMerchantsSuccess
  | LoadInDraftMerchantsFail
  | LoadMerchant
  | LoadMerchantSuccess
  | LoadMerchantFail
  | LoadMerchantRejectionReasons
  | LoadMerchantRejectionReasonsSuccess
  | LoadMerchantRejectionReasonsFail
  | LoadMerchantAccountStatus
  | LoadMerchantAccountStatusSuccess
  | LoadMerchantAccountStatusFail
  | ClearMerchant;
