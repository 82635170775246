import { Action } from '@ngrx/store';
import { NavigationListSuccess } from 'app/core/models/navigation';
import { FuseNavigationItem } from '@fuse/components/navigation';

export enum NavigationActionTypes {
  LoadNavigations = '[Navigations] Load Navigations',
  LoadNavigationsSuccess = '[Navigations] Load Navigations Success',
  LoadNavigationsFail = '[Navigations] Load Navigations Fail',

  LoadNavigationMessage = '[Navigation] Load Success Message',
  StopNavigationLoading = '[Navigation] Stop Loading',

  LoadSelectedModule = '[Navigation] Load Selected Module',

}

export class LoadNavigations implements Action {
  readonly type = NavigationActionTypes.LoadNavigations;
}

export class LoadNavigationsSuccess implements Action {
  readonly type = NavigationActionTypes.LoadNavigationsSuccess;
  constructor(public payload: NavigationListSuccess) {}
}

export class LoadNavigationsFail implements Action {
  readonly type = NavigationActionTypes.LoadNavigationsFail;
  constructor(public payload: any) {}
}

export class LoadNavigationMessage implements Action {
  readonly type = NavigationActionTypes.LoadNavigationMessage;
  constructor(public payload?: string) {}
}

export class LoadSelectedModule implements Action {
  readonly type = NavigationActionTypes.LoadSelectedModule;
  constructor(public payload?: FuseNavigationItem) {}
}

export class StopNavigationLoading implements Action {
  readonly type = NavigationActionTypes.StopNavigationLoading;
}

export type NavigationActions =
  | LoadNavigations
  | LoadNavigationsSuccess
  | LoadNavigationsFail
  | LoadNavigationMessage
  | StopNavigationLoading
  | LoadSelectedModule;
