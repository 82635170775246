import { Action } from '@ngrx/store';
import { CountryReqParams, CountryRespData, DocumentTypes, DocumentTypesReq, Gender, BankList, MaritalStatus } from 'app/core/models';

export enum GeneralActionTypes {
  LoadDocumentTypes = '[Document Types] Load Document Types',
  LoadDocumentTypesSuccess = '[Document Types] Load Document Types Success',
  LoadDocumentTypesFail = '[Document Types] Load Document Types Fail',

  LoadMaritalStatus = '[Marital Status] Load Marital Status',
  LoadMaritalStatusSuccess = '[Marital Status] Load Marital Status Success',
  LoadMaritalStatusFail = '[Marital Status] Load Marital Status Fail',

  LoadGenders = '[Genders] Load Genders',
  LoadGendersSuccess = '[Genders] Load Genders Success',
  LoadGendersFail = '[Genders] Load Genders Fail',

  LoadCountries = '[Countries] Load Countries',
  LoadCountriesSuccess = '[Countries] Load Countries Success',
  LoadCountriesFail = '[Countries] Load Countries Fail',

  LoadBankList = '[BankList] Load BankList',
  LoadBankListSuccess = '[BankList] Load Bank List Success',
  LoadBankListFail = '[BankList] Load Bank List Fail',
}

export class LoadDocumentTypes implements Action {
  readonly type = GeneralActionTypes.LoadDocumentTypes;
  constructor(public payload: DocumentTypesReq) {}
}

export class LoadDocumentTypesSuccess implements Action {
  readonly type = GeneralActionTypes.LoadDocumentTypesSuccess;
  constructor(public payload: DocumentTypes[]) {}
}

export class LoadDocumentTypesFail implements Action {
  readonly type = GeneralActionTypes.LoadDocumentTypesFail;
  constructor(public payload: any) {}
}

export class LoadMaritalStatus implements Action {
  readonly type = GeneralActionTypes.LoadMaritalStatus;
}

export class LoadMaritalStatusSuccess implements Action {
  readonly type = GeneralActionTypes.LoadMaritalStatusSuccess;
  constructor(public payload: MaritalStatus[]) {}
}

export class LoadMaritalStatusFail implements Action {
  readonly type = GeneralActionTypes.LoadMaritalStatusFail;
  constructor(public payload: any) {}
}

export class LoadGenders implements Action {
  readonly type = GeneralActionTypes.LoadGenders;
}

export class LoadGendersSuccess implements Action {
  readonly type = GeneralActionTypes.LoadGendersSuccess;
  constructor(public payload: Gender[]) {}
}

export class LoadGendersFail implements Action {
  readonly type = GeneralActionTypes.LoadGendersFail;
  constructor(public payload: any) {}
}

export class LoadCountries implements Action {
  readonly type = GeneralActionTypes.LoadCountries;
  constructor(public payload?: CountryReqParams) {}
}

export class LoadCountriesSuccess implements Action {
  readonly type = GeneralActionTypes.LoadCountriesSuccess;
  constructor(public payload: CountryRespData[]) {}
}

export class LoadCountriesFail implements Action {
  readonly type = GeneralActionTypes.LoadCountriesFail;
  constructor(public payload: any) {}
}

export class LoadBankList implements Action {
  readonly type = GeneralActionTypes.LoadBankList;
}

export class LoadBankListSuccess implements Action {
  readonly type = GeneralActionTypes.LoadBankListSuccess;
  constructor(public payload: BankList[]) {}
}

export class LoadBankListFail implements Action {
  readonly type = GeneralActionTypes.LoadBankListFail;
  constructor(public payload: any) {}
}

export type GeneralActions =
  | LoadDocumentTypes
  | LoadDocumentTypesSuccess
  | LoadDocumentTypesFail
  | LoadMaritalStatus
  | LoadMaritalStatusSuccess
  | LoadMaritalStatusFail
  | LoadGenders
  | LoadGendersSuccess
  | LoadGendersFail
  | LoadCountries
  | LoadCountriesSuccess
  | LoadCountriesFail
  | LoadBankList
  | LoadBankListSuccess
  | LoadBankListFail
