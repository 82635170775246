import { Customer } from 'app/core/models';

export interface CustomerData {
  isLoading: boolean;
  error: Error;
  approvedcustomers: Customer[];
  pendingcustomers: Customer[];
  selectedcustomer: Customer[];
  indraftcustomers: Customer[];
  rejectionReasons: string[];
  accountStatus: string[];
}

export interface CustomerState {
  readonly customer: CustomerData
}

export const initialCustomerState: CustomerData = {
  isLoading: false,
  error: null,
  approvedcustomers: null,
  pendingcustomers: null,
  indraftcustomers: null,
  selectedcustomer: null,
  rejectionReasons: null,
  accountStatus: null,
}
