export interface User {
  id: string;
  name: string;
  email: string;
  avatar?: string;
  status?: string;
  UUID: string;
  Email: string;
  UserName: string;
  FullName: string;
  Status: string;
  LockoutStatus: string;
  IsAdmin: string;
  LoginAttempt: string;
  LoggedIn: string;
  DateCreated: string;
  UpdatedBy: string;
  CreatedBy: string;
  FirstName: string;
  LastName: string;
  LastLogonDate: string;
  ChangePassword: string;
  is_bvn_verified: string;
  is_2FA_enabled: string;
  phone_number: string;
  created_at: string;
  updated_at: string;
}

export interface UserReq {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  password: string;
}
