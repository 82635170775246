import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, switchMap, map, catchError, of, tap } from 'rxjs';
import * as navigationActions from './navigation.actions';
import { AppState } from '../root-state';
import { NavigationService } from 'app/core/service/navigation.service';
import { getNavigationModules } from '.';
import { AppStorage } from 'app/core/utils/storage';

@Injectable()
export class NavigationEffects {
  constructor(
    private _actions$: Actions,
    private navigationService: NavigationService,
    private store: Store<AppState>,
  ) {}

  loadNavigations$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<navigationActions.LoadNavigations>(
        navigationActions.NavigationActionTypes.LoadNavigations,
      ),
      concatLatestFrom(() => this.store.select(getNavigationModules)),
      switchMap(([action, modules]) => {
        if (modules.length === 0) {
          return this.navigationService.getUserAccess().pipe(
            map(items => new navigationActions.LoadNavigationsSuccess(items)),
            tap(items => AppStorage.set('navItems', items.payload.data.access)),
            catchError(error => of(new navigationActions.LoadNavigationsFail(error))),
          );
        }
        return of(new navigationActions.StopNavigationLoading());
      }),
    ),
  );
}
