import { createSelector } from '@ngrx/store';
import { AppState } from '../root-state';
import { SectionData } from '.';

const getSectionState = (state: AppState): SectionData => state.section;

export const getSectionLoading = createSelector(
  getSectionState,
  state => state.isLoading,
);

export const getSectionError = createSelector(getSectionState, state => state.error);

export const getSections = createSelector(
  getSectionState,
  state => state.sections,
);

export const getSection = createSelector(
  getSectionState,
  state => state.section,
);
