import { createSelector } from '@ngrx/store';
import { UserData } from '.';
import { AppState } from '../root-state';

const getUserState = (state: AppState): UserData => state.user;

export const getUserLoading = createSelector(
  getUserState,
  state => state.isLoading,
);

export const getUserError = createSelector(getUserState, state => state.error);

export const getUsers = createSelector(
  getUserState,
  state => state.users,
);

export const getUser = createSelector(
  getUserState,
  state => state.user,
);
