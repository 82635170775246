import { AccountOfficer2 } from 'app/core/models';

export interface AccountOfficerData {
  isLoading: boolean;
  error: Error;
  accountofficers: AccountOfficer2[];
}

export interface AccountOfficerState {
  readonly accountofficer: AccountOfficerData
}

export const initialAccountOfficerState: AccountOfficerData = {
  isLoading: false,
  error: null,
  accountofficers: null,
}
