import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { GroupService } from 'app/core/service/group.service';
import { Observable, switchMap, map, catchError, of } from 'rxjs';
import * as groupActions from './group.actions';

@Injectable()
export class GroupEffects {
  constructor(private _actions$: Actions, private _groupService: GroupService) {}

  loadGroup$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<groupActions.LoadGroups>(
        groupActions.GroupActionTypes.LoadGroups,
      ),
      switchMap(() =>
        this._groupService.getGroups().pipe(
          map(items => new groupActions.LoadGroupsSuccess(items)),
          catchError(error =>
            of(new groupActions.LoadGroupsFail(error)),
          ),
        ),
      ),
    ),
  );
}
