import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { UserService } from 'app/core/service/user.service';

@Directive({
  selector: '[appPermissionCheck]',
})
export class PermissionCheckDirective implements OnInit {
  @Input('appPermissionCheck') permission: string;

  constructor(
    private userService: UserService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (!this.userService.hasPermission(this.permission)) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
    }
  }
}
