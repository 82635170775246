import * as moment from 'moment';

/* tslint:disable:max-line-length */
export const finance = {
  accountBalance: {
    growRate: 38.33,
    ami: 45332,
    series: [
      {
        name: 'Predicted',
        data: [
          {
            x: moment().subtract(12, 'months').day(1).toDate(),
            y: 48.84,
          },
          {
            x: moment().subtract(12, 'months').day(4).toDate(),
            y: 53.51,
          },
          {
            x: moment().subtract(12, 'months').day(7).toDate(),
            y: 52.93,
          },
          {
            x: moment().subtract(12, 'months').day(10).toDate(),
            y: 49.08,
          },
          {
            x: moment().subtract(12, 'months').day(13).toDate(),
            y: 50.27,
          },
          {
            x: moment().subtract(12, 'months').day(16).toDate(),
            y: 48.37,
          },
          {
            x: moment().subtract(12, 'months').day(19).toDate(),
            y: 44.84,
          },
          {
            x: moment().subtract(12, 'months').day(22).toDate(),
            y: 40.71,
          },
          {
            x: moment().subtract(12, 'months').day(25).toDate(),
            y: 41.24,
          },
          {
            x: moment().subtract(12, 'months').day(28).toDate(),
            y: 45.63,
          },
          {
            x: moment().subtract(11, 'months').day(1).toDate(),
            y: 38.2,
          },
          {
            x: moment().subtract(11, 'months').day(4).toDate(),
            y: 39.68,
          },
          {
            x: moment().subtract(11, 'months').day(7).toDate(),
            y: 41.02,
          },
          {
            x: moment().subtract(11, 'months').day(10).toDate(),
            y: 39.41,
          },
          {
            x: moment().subtract(11, 'months').day(13).toDate(),
            y: 35.66,
          },
          {
            x: moment().subtract(11, 'months').day(16).toDate(),
            y: 38.53,
          },
          {
            x: moment().subtract(11, 'months').day(19).toDate(),
            y: 38.53,
          },
          {
            x: moment().subtract(11, 'months').day(22).toDate(),
            y: 40.69,
          },
          {
            x: moment().subtract(11, 'months').day(25).toDate(),
            y: 38.79,
          },
          {
            x: moment().subtract(11, 'months').day(28).toDate(),
            y: 42.98,
          },
          {
            x: moment().subtract(10, 'months').day(1).toDate(),
            y: 43.55,
          },
          {
            x: moment().subtract(10, 'months').day(4).toDate(),
            y: 40.65,
          },
          {
            x: moment().subtract(10, 'months').day(7).toDate(),
            y: 36.5,
          },
          {
            x: moment().subtract(10, 'months').day(10).toDate(),
            y: 33.79,
          },
          {
            x: moment().subtract(10, 'months').day(13).toDate(),
            y: 31.91,
          },
          {
            x: moment().subtract(10, 'months').day(16).toDate(),
            y: 29.68,
          },
          {
            x: moment().subtract(10, 'months').day(19).toDate(),
            y: 29.57,
          },
          {
            x: moment().subtract(10, 'months').day(22).toDate(),
            y: 33.13,
          },
          {
            x: moment().subtract(10, 'months').day(25).toDate(),
            y: 37.08,
          },
          {
            x: moment().subtract(10, 'months').day(28).toDate(),
            y: 35.86,
          },
          {
            x: moment().subtract(9, 'months').day(1).toDate(),
            y: 39.65,
          },
          {
            x: moment().subtract(9, 'months').day(4).toDate(),
            y: 39.01,
          },
          {
            x: moment().subtract(9, 'months').day(7).toDate(),
            y: 34.1,
          },
          {
            x: moment().subtract(9, 'months').day(10).toDate(),
            y: 37.48,
          },
          {
            x: moment().subtract(9, 'months').day(13).toDate(),
            y: 39.29,
          },
          {
            x: moment().subtract(9, 'months').day(16).toDate(),
            y: 38.46,
          },
          {
            x: moment().subtract(9, 'months').day(19).toDate(),
            y: 37.71,
          },
          {
            x: moment().subtract(9, 'months').day(22).toDate(),
            y: 40.15,
          },
          {
            x: moment().subtract(9, 'months').day(25).toDate(),
            y: 35.89,
          },
          {
            x: moment().subtract(9, 'months').day(28).toDate(),
            y: 31.5,
          },
          {
            x: moment().subtract(8, 'months').day(1).toDate(),
            y: 30.5,
          },
          {
            x: moment().subtract(8, 'months').day(4).toDate(),
            y: 25.74,
          },
          {
            x: moment().subtract(8, 'months').day(7).toDate(),
            y: 28.23,
          },
          {
            x: moment().subtract(8, 'months').day(10).toDate(),
            y: 28.48,
          },
          {
            x: moment().subtract(8, 'months').day(13).toDate(),
            y: 30.0,
          },
          {
            x: moment().subtract(8, 'months').day(16).toDate(),
            y: 32.16,
          },
          {
            x: moment().subtract(8, 'months').day(19).toDate(),
            y: 32.99,
          },
          {
            x: moment().subtract(8, 'months').day(22).toDate(),
            y: 37.68,
          },
          {
            x: moment().subtract(8, 'months').day(25).toDate(),
            y: 35.24,
          },
          {
            x: moment().subtract(8, 'months').day(28).toDate(),
            y: 39.18,
          },
          {
            x: moment().subtract(7, 'months').day(1).toDate(),
            y: 41.45,
          },
          {
            x: moment().subtract(7, 'months').day(4).toDate(),
            y: 43.78,
          },
          {
            x: moment().subtract(7, 'months').day(7).toDate(),
            y: 39.41,
          },
          {
            x: moment().subtract(7, 'months').day(10).toDate(),
            y: 39.32,
          },
          {
            x: moment().subtract(7, 'months').day(13).toDate(),
            y: 43.8,
          },
          {
            x: moment().subtract(7, 'months').day(16).toDate(),
            y: 42.43,
          },
          {
            x: moment().subtract(7, 'months').day(19).toDate(),
            y: 43.67,
          },
          {
            x: moment().subtract(7, 'months').day(22).toDate(),
            y: 38.79,
          },
          {
            x: moment().subtract(7, 'months').day(25).toDate(),
            y: 43.57,
          },
          {
            x: moment().subtract(7, 'months').day(28).toDate(),
            y: 41.81,
          },
          {
            x: moment().subtract(6, 'months').day(1).toDate(),
            y: 46.19,
          },
          {
            x: moment().subtract(6, 'months').day(4).toDate(),
            y: 47.69,
          },
          {
            x: moment().subtract(6, 'months').day(7).toDate(),
            y: 49.01,
          },
          {
            x: moment().subtract(6, 'months').day(10).toDate(),
            y: 46.4,
          },
          {
            x: moment().subtract(6, 'months').day(13).toDate(),
            y: 51.28,
          },
          {
            x: moment().subtract(6, 'months').day(16).toDate(),
            y: 50.15,
          },
          {
            x: moment().subtract(6, 'months').day(19).toDate(),
            y: 53.6,
          },
          {
            x: moment().subtract(6, 'months').day(22).toDate(),
            y: 56.08,
          },
          {
            x: moment().subtract(6, 'months').day(25).toDate(),
            y: 52.72,
          },
          {
            x: moment().subtract(6, 'months').day(28).toDate(),
            y: 56.6,
          },
          {
            x: moment().subtract(5, 'months').day(1).toDate(),
            y: 58.36,
          },
          {
            x: moment().subtract(5, 'months').day(4).toDate(),
            y: 56.59,
          },
          {
            x: moment().subtract(5, 'months').day(7).toDate(),
            y: 55.75,
          },
          {
            x: moment().subtract(5, 'months').day(10).toDate(),
            y: 54.74,
          },
          {
            x: moment().subtract(5, 'months').day(13).toDate(),
            y: 54.27,
          },
          {
            x: moment().subtract(5, 'months').day(16).toDate(),
            y: 58.65,
          },
          {
            x: moment().subtract(5, 'months').day(19).toDate(),
            y: 57.0,
          },
          {
            x: moment().subtract(5, 'months').day(22).toDate(),
            y: 60.52,
          },
          {
            x: moment().subtract(5, 'months').day(25).toDate(),
            y: 57.6,
          },
          {
            x: moment().subtract(5, 'months').day(28).toDate(),
            y: 56.48,
          },
          {
            x: moment().subtract(4, 'months').day(1).toDate(),
            y: 54.35,
          },
          {
            x: moment().subtract(4, 'months').day(4).toDate(),
            y: 52.39,
          },
          {
            x: moment().subtract(4, 'months').day(7).toDate(),
            y: 54.52,
          },
          {
            x: moment().subtract(4, 'months').day(10).toDate(),
            y: 54.16,
          },
          {
            x: moment().subtract(4, 'months').day(13).toDate(),
            y: 51.95,
          },
          {
            x: moment().subtract(4, 'months').day(16).toDate(),
            y: 51.19,
          },
          {
            x: moment().subtract(4, 'months').day(19).toDate(),
            y: 46.35,
          },
          {
            x: moment().subtract(4, 'months').day(22).toDate(),
            y: 48.33,
          },
          {
            x: moment().subtract(4, 'months').day(25).toDate(),
            y: 45.84,
          },
          {
            x: moment().subtract(4, 'months').day(28).toDate(),
            y: 48.22,
          },
          {
            x: moment().subtract(3, 'months').day(1).toDate(),
            y: 45.82,
          },
          {
            x: moment().subtract(3, 'months').day(4).toDate(),
            y: 43.48,
          },
          {
            x: moment().subtract(3, 'months').day(7).toDate(),
            y: 41.32,
          },
          {
            x: moment().subtract(3, 'months').day(10).toDate(),
            y: 40.99,
          },
          {
            x: moment().subtract(3, 'months').day(13).toDate(),
            y: 38.49,
          },
          {
            x: moment().subtract(3, 'months').day(16).toDate(),
            y: 40.1,
          },
          {
            x: moment().subtract(3, 'months').day(19).toDate(),
            y: 44.86,
          },
          {
            x: moment().subtract(3, 'months').day(22).toDate(),
            y: 44.03,
          },
          {
            x: moment().subtract(3, 'months').day(25).toDate(),
            y: 41.41,
          },
          {
            x: moment().subtract(3, 'months').day(28).toDate(),
            y: 37.8,
          },
          {
            x: moment().subtract(2, 'months').day(1).toDate(),
            y: 35.24,
          },
          {
            x: moment().subtract(2, 'months').day(4).toDate(),
            y: 32.12,
          },
          {
            x: moment().subtract(2, 'months').day(7).toDate(),
            y: 35.68,
          },
          {
            x: moment().subtract(2, 'months').day(10).toDate(),
            y: 38.0,
          },
          {
            x: moment().subtract(2, 'months').day(13).toDate(),
            y: 37.96,
          },
          {
            x: moment().subtract(2, 'months').day(16).toDate(),
            y: 38.7,
          },
          {
            x: moment().subtract(2, 'months').day(19).toDate(),
            y: 37.45,
          },
          {
            x: moment().subtract(2, 'months').day(22).toDate(),
            y: 37.51,
          },
          {
            x: moment().subtract(2, 'months').day(25).toDate(),
            y: 33.1,
          },
          {
            x: moment().subtract(2, 'months').day(28).toDate(),
            y: 35.09,
          },
          {
            x: moment().subtract(1, 'months').day(1).toDate(),
            y: 31.87,
          },
          {
            x: moment().subtract(1, 'months').day(4).toDate(),
            y: 29.18,
          },
          {
            x: moment().subtract(1, 'months').day(7).toDate(),
            y: 31.91,
          },
          {
            x: moment().subtract(1, 'months').day(10).toDate(),
            y: 34.37,
          },
          {
            x: moment().subtract(1, 'months').day(13).toDate(),
            y: 32.91,
          },
          {
            x: moment().subtract(1, 'months').day(16).toDate(),
            y: 33.17,
          },
          {
            x: moment().subtract(1, 'months').day(19).toDate(),
            y: 37.16,
          },
          {
            x: moment().subtract(1, 'months').day(22).toDate(),
            y: 32.6,
          },
          {
            x: moment().subtract(1, 'months').day(25).toDate(),
            y: 36.94,
          },
          {
            x: moment().subtract(1, 'months').day(28).toDate(),
            y: 35.98,
          },
        ],
      },
      {
        name: 'Actual',
        data: [
          {
            x: moment().subtract(12, 'months').day(1).toDate(),
            y: 20.21,
          },
          {
            x: moment().subtract(12, 'months').day(4).toDate(),
            y: 17.49,
          },
          {
            x: moment().subtract(12, 'months').day(7).toDate(),
            y: 16.54,
          },
          {
            x: moment().subtract(12, 'months').day(10).toDate(),
            y: 19.0,
          },
          {
            x: moment().subtract(12, 'months').day(13).toDate(),
            y: 16.47,
          },
          {
            x: moment().subtract(12, 'months').day(16).toDate(),
            y: 13.15,
          },
          {
            x: moment().subtract(12, 'months').day(19).toDate(),
            y: 18.07,
          },
          {
            x: moment().subtract(12, 'months').day(22).toDate(),
            y: 17.93,
          },
          {
            x: moment().subtract(12, 'months').day(25).toDate(),
            y: 18.92,
          },
          {
            x: moment().subtract(12, 'months').day(28).toDate(),
            y: 18.46,
          },
          {
            x: moment().subtract(11, 'months').day(1).toDate(),
            y: 18.04,
          },
          {
            x: moment().subtract(11, 'months').day(4).toDate(),
            y: 17.78,
          },
          {
            x: moment().subtract(11, 'months').day(7).toDate(),
            y: 20.15,
          },
          {
            x: moment().subtract(11, 'months').day(10).toDate(),
            y: 18.92,
          },
          {
            x: moment().subtract(11, 'months').day(13).toDate(),
            y: 17.08,
          },
          {
            x: moment().subtract(11, 'months').day(16).toDate(),
            y: 17.11,
          },
          {
            x: moment().subtract(11, 'months').day(19).toDate(),
            y: 15.7,
          },
          {
            x: moment().subtract(11, 'months').day(22).toDate(),
            y: 15.07,
          },
          {
            x: moment().subtract(11, 'months').day(25).toDate(),
            y: 14.51,
          },
          {
            x: moment().subtract(11, 'months').day(28).toDate(),
            y: 15.22,
          },
          {
            x: moment().subtract(10, 'months').day(1).toDate(),
            y: 19.77,
          },
          {
            x: moment().subtract(10, 'months').day(4).toDate(),
            y: 23.67,
          },
          {
            x: moment().subtract(10, 'months').day(7).toDate(),
            y: 27.98,
          },
          {
            x: moment().subtract(10, 'months').day(10).toDate(),
            y: 30.8,
          },
          {
            x: moment().subtract(10, 'months').day(13).toDate(),
            y: 28.56,
          },
          {
            x: moment().subtract(10, 'months').day(16).toDate(),
            y: 27.45,
          },
          {
            x: moment().subtract(10, 'months').day(19).toDate(),
            y: 27.5,
          },
          {
            x: moment().subtract(10, 'months').day(22).toDate(),
            y: 27.28,
          },
          {
            x: moment().subtract(10, 'months').day(25).toDate(),
            y: 24.36,
          },
          {
            x: moment().subtract(10, 'months').day(28).toDate(),
            y: 22.89,
          },
          {
            x: moment().subtract(9, 'months').day(1).toDate(),
            y: 28.04,
          },
          {
            x: moment().subtract(9, 'months').day(4).toDate(),
            y: 27.77,
          },
          {
            x: moment().subtract(9, 'months').day(7).toDate(),
            y: 30.24,
          },
          {
            x: moment().subtract(9, 'months').day(10).toDate(),
            y: 26.57,
          },
          {
            x: moment().subtract(9, 'months').day(13).toDate(),
            y: 22.18,
          },
          {
            x: moment().subtract(9, 'months').day(16).toDate(),
            y: 19.64,
          },
          {
            x: moment().subtract(9, 'months').day(19).toDate(),
            y: 16.74,
          },
          {
            x: moment().subtract(9, 'months').day(22).toDate(),
            y: 17.21,
          },
          {
            x: moment().subtract(9, 'months').day(25).toDate(),
            y: 20.05,
          },
          {
            x: moment().subtract(9, 'months').day(28).toDate(),
            y: 16.13,
          },
          {
            x: moment().subtract(8, 'months').day(1).toDate(),
            y: 10.71,
          },
          {
            x: moment().subtract(8, 'months').day(4).toDate(),
            y: 7.99,
          },
          {
            x: moment().subtract(8, 'months').day(7).toDate(),
            y: 11.33,
          },
          {
            x: moment().subtract(8, 'months').day(10).toDate(),
            y: 15.36,
          },
          {
            x: moment().subtract(8, 'months').day(13).toDate(),
            y: 20.16,
          },
          {
            x: moment().subtract(8, 'months').day(16).toDate(),
            y: 22.56,
          },
          {
            x: moment().subtract(8, 'months').day(19).toDate(),
            y: 19.34,
          },
          {
            x: moment().subtract(8, 'months').day(22).toDate(),
            y: 18.32,
          },
          {
            x: moment().subtract(8, 'months').day(25).toDate(),
            y: 20.75,
          },
          {
            x: moment().subtract(8, 'months').day(28).toDate(),
            y: 17.09,
          },
          {
            x: moment().subtract(7, 'months').day(1).toDate(),
            y: 18.31,
          },
          {
            x: moment().subtract(7, 'months').day(4).toDate(),
            y: 14.34,
          },
          {
            x: moment().subtract(7, 'months').day(7).toDate(),
            y: 9.93,
          },
          {
            x: moment().subtract(7, 'months').day(10).toDate(),
            y: 10.64,
          },
          {
            x: moment().subtract(7, 'months').day(13).toDate(),
            y: 6.18,
          },
          {
            x: moment().subtract(7, 'months').day(16).toDate(),
            y: 10.32,
          },
          {
            x: moment().subtract(7, 'months').day(19).toDate(),
            y: 12.8,
          },
          {
            x: moment().subtract(7, 'months').day(22).toDate(),
            y: 13.44,
          },
          {
            x: moment().subtract(7, 'months').day(25).toDate(),
            y: 18.35,
          },
          {
            x: moment().subtract(7, 'months').day(28).toDate(),
            y: 22.87,
          },
          {
            x: moment().subtract(6, 'months').day(1).toDate(),
            y: 26.92,
          },
          {
            x: moment().subtract(6, 'months').day(4).toDate(),
            y: 22.5,
          },
          {
            x: moment().subtract(6, 'months').day(7).toDate(),
            y: 18.14,
          },
          {
            x: moment().subtract(6, 'months').day(10).toDate(),
            y: 19.06,
          },
          {
            x: moment().subtract(6, 'months').day(13).toDate(),
            y: 19.73,
          },
          {
            x: moment().subtract(6, 'months').day(16).toDate(),
            y: 18.82,
          },
          {
            x: moment().subtract(6, 'months').day(19).toDate(),
            y: 23.33,
          },
          {
            x: moment().subtract(6, 'months').day(22).toDate(),
            y: 20.48,
          },
          {
            x: moment().subtract(6, 'months').day(25).toDate(),
            y: 25.47,
          },
          {
            x: moment().subtract(6, 'months').day(28).toDate(),
            y: 28.84,
          },
          {
            x: moment().subtract(5, 'months').day(1).toDate(),
            y: 27.71,
          },
          {
            x: moment().subtract(5, 'months').day(4).toDate(),
            y: 25.22,
          },
          {
            x: moment().subtract(5, 'months').day(7).toDate(),
            y: 25.43,
          },
          {
            x: moment().subtract(5, 'months').day(10).toDate(),
            y: 24.13,
          },
          {
            x: moment().subtract(5, 'months').day(13).toDate(),
            y: 20.02,
          },
          {
            x: moment().subtract(5, 'months').day(16).toDate(),
            y: 18.38,
          },
          {
            x: moment().subtract(5, 'months').day(19).toDate(),
            y: 18.3,
          },
          {
            x: moment().subtract(5, 'months').day(22).toDate(),
            y: 18.72,
          },
          {
            x: moment().subtract(5, 'months').day(25).toDate(),
            y: 22.46,
          },
          {
            x: moment().subtract(5, 'months').day(28).toDate(),
            y: 21.71,
          },
          {
            x: moment().subtract(4, 'months').day(1).toDate(),
            y: 29.88,
          },
          {
            x: moment().subtract(4, 'months').day(4).toDate(),
            y: 26.94,
          },
          {
            x: moment().subtract(4, 'months').day(7).toDate(),
            y: 28.06,
          },
          {
            x: moment().subtract(4, 'months').day(10).toDate(),
            y: 30.4,
          },
          {
            x: moment().subtract(4, 'months').day(13).toDate(),
            y: 28.98,
          },
          {
            x: moment().subtract(4, 'months').day(16).toDate(),
            y: 30.13,
          },
          {
            x: moment().subtract(4, 'months').day(19).toDate(),
            y: 27.6,
          },
          {
            x: moment().subtract(4, 'months').day(22).toDate(),
            y: 30.21,
          },
          {
            x: moment().subtract(4, 'months').day(25).toDate(),
            y: 26.88,
          },
          {
            x: moment().subtract(4, 'months').day(28).toDate(),
            y: 25.72,
          },
          {
            x: moment().subtract(3, 'months').day(1).toDate(),
            y: 27.89,
          },
          {
            x: moment().subtract(3, 'months').day(4).toDate(),
            y: 30.69,
          },
          {
            x: moment().subtract(3, 'months').day(7).toDate(),
            y: 31.42,
          },
          {
            x: moment().subtract(3, 'months').day(10).toDate(),
            y: 36.14,
          },
          {
            x: moment().subtract(3, 'months').day(13).toDate(),
            y: 32.02,
          },
          {
            x: moment().subtract(3, 'months').day(16).toDate(),
            y: 27.3,
          },
          {
            x: moment().subtract(3, 'months').day(19).toDate(),
            y: 29.51,
          },
          {
            x: moment().subtract(3, 'months').day(22).toDate(),
            y: 32.67,
          },
          {
            x: moment().subtract(3, 'months').day(25).toDate(),
            y: 28.82,
          },
          {
            x: moment().subtract(3, 'months').day(28).toDate(),
            y: 28.85,
          },
          {
            x: moment().subtract(2, 'months').day(1).toDate(),
            y: 29.15,
          },
          {
            x: moment().subtract(2, 'months').day(4).toDate(),
            y: 27.9,
          },
          {
            x: moment().subtract(2, 'months').day(7).toDate(),
            y: 30.71,
          },
          {
            x: moment().subtract(2, 'months').day(10).toDate(),
            y: 28.02,
          },
          {
            x: moment().subtract(2, 'months').day(13).toDate(),
            y: 23.82,
          },
          {
            x: moment().subtract(2, 'months').day(16).toDate(),
            y: 18.83,
          },
          {
            x: moment().subtract(2, 'months').day(19).toDate(),
            y: 14.48,
          },
          {
            x: moment().subtract(2, 'months').day(22).toDate(),
            y: 11.76,
          },
          {
            x: moment().subtract(2, 'months').day(25).toDate(),
            y: 12.75,
          },
          {
            x: moment().subtract(2, 'months').day(28).toDate(),
            y: 11.36,
          },
          {
            x: moment().subtract(1, 'months').day(1).toDate(),
            y: 11.6,
          },
          {
            x: moment().subtract(1, 'months').day(4).toDate(),
            y: 15.24,
          },
          {
            x: moment().subtract(1, 'months').day(7).toDate(),
            y: 13.05,
          },
          {
            x: moment().subtract(1, 'months').day(10).toDate(),
            y: 17.25,
          },
          {
            x: moment().subtract(1, 'months').day(13).toDate(),
            y: 18.5,
          },
          {
            x: moment().subtract(1, 'months').day(16).toDate(),
            y: 23.04,
          },
          {
            x: moment().subtract(1, 'months').day(19).toDate(),
            y: 21.87,
          },
          {
            x: moment().subtract(1, 'months').day(22).toDate(),
            y: 25.97,
          },
          {
            x: moment().subtract(1, 'months').day(25).toDate(),
            y: 22.46,
          },
          {
            x: moment().subtract(1, 'months').day(28).toDate(),
            y: 17.67,
          },
        ],
      },
    ],
  },
  budget: {
    expenses: 11763.34,
    expensesLimit: 20000,
    savings: 10974.12,
    savingsGoal: 250000,
    bills: 1789.22,
    billsLimit: 1000,
  },
  previousStatement: {
    status: 'paid',
    date: moment().startOf('day').subtract(15, 'days').format('LL'),
    limit: 34500,
    spent: 27221.21,
    minimum: 7331.94,
  },
  currentStatement: {
    status: 'pending',
    date: moment().startOf('day').subtract(15, 'days').add(1, 'month').format('LL'),
    limit: 34500,
    spent: 39819.41,
    minimum: 9112.51,
  },
  recentTransactions: [
    {
      id: '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
      transactionId: '528651571NT',
      name: 'Morgan Page',
      amount: +1358.75,
      status: 'completed',
      date: '2019-10-07T22:22:37.274Z',
    },
    {
      id: '2dec6074-98bd-4623-9526-6480e4776569',
      transactionId: '421436904YT',
      name: 'Nita Hebert',
      amount: -1042.82,
      status: 'completed',
      date: '2019-12-18T14:51:24.461Z',
    },
    {
      id: 'ae7c065f-4197-4021-a799-7a221822ad1d',
      transactionId: '685377421YT',
      name: 'Marsha Chambers',
      amount: +1828.16,
      status: 'pending',
      date: '2019-12-25T17:52:14.304Z',
    },
    {
      id: '0c43dd40-74f6-49d5-848a-57a4a45772ab',
      transactionId: '884960091RT',
      name: 'Charmaine Jackson',
      amount: +1647.55,
      status: 'completed',
      date: '2019-11-29T06:32:16.111Z',
    },
    {
      id: 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
      transactionId: '361402213NT',
      name: 'Maura Carey',
      amount: -927.43,
      status: 'completed',
      date: '2019-11-24T12:13:23.064Z',
    },
  ],
  approvedCustomers: [
    {
      PersonMasterID: '10',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: [
        {
          bank_name: 'Kuda Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435678',
          default: '0',
        },
        {
          bank_name: 'Polaris Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0412435651',
          default: '0',
        },
        {
          bank_name: 'Zenith Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435620',
          default: '1',
        },
      ],
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: '1',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          default: '0',
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: '1',
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: '0',
        },
        {
          email: 'gabbyo@gmail.com',
          default: '0',
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: '1',
        },
        {
          phone: '09013245673',
          default: '0',
        },
      ],
      NOKFirstName: 'Gabriel',
      NOKLastName: 'Ikemefuna',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: '09312311213',
      NOKEmailAddress: 'samomokpo@gmail.com',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentNote: 'NULL',
      DocumentPath: null,
      DocumentUID: '0f1883fd-5af6-4e60-947f-aedd672f6bf1',
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: '230',
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '11',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: [
        {
          bank_name: 'Kuda Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435678',
          default: '0',
        },
        {
          bank_name: 'Polaris Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0412435651',
          default: '0',
        },
        {
          bank_name: 'Zenith Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435620',
          default: '1',
        },
      ],
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: '1',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          default: '0',
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: '1',
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: '0',
        },
        {
          email: 'gabbyo@gmail.com',
          default: '0',
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: '1',
        },
        {
          phone: '09013245673',
          default: '0',
        },
      ],
      NOKFirstName: 'Gabriel',
      NOKLastName: 'Ikemefuna',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: '09312311213',
      NOKEmailAddress: 'samomokpo@gmail.com',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentNote: 'NULL',
      DocumentPath: {
        IdentificationProof:
          'public/files/identities/12345698996/12345698996_IdentificationProof.png',
        Photograph: 'public/files/identities/12345698996/12345698996_Photograph.png',
        ProofofResidence:
          'public/files/identities/12345698996/12345698996_ProofofResidence.png',
        Signature: 'public/files/identities/12345698996/12345698996_Signature.png',
      },
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: '231',
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '27',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: [
        {
          bank_name: 'Kuda Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435678',
          default: '0',
        },
        {
          bank_name: 'Polaris Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0412435651',
          default: '0',
        },
        {
          bank_name: 'Zenith Bank',
          account_name: 'Gabriel Omokpo',
          account_number: '0312435620',
          default: '1',
        },
      ],
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: '1',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          default: '0',
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: '1',
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: '0',
        },
        {
          email: 'gabbyo@gmail.com',
          default: '0',
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: '1',
        },
        {
          phone: '09013245673',
          default: '0',
        },
      ],
      NOKFirstName: 'Gabriel',
      NOKLastName: 'Ikemefuna',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: '09312311213',
      NOKEmailAddress: 'ndubisiomokpo@gmail.com',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentNote: 'NULL',
      DocumentPath: null,
      DocumentUID: 'NULL',
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: '232',
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '34',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: [
        {
          bank_name: 'Access Bank Plc',
          account_name: 'Kenny',
          account_number: '2343234543',
          default: true,
        },
        {
          bank_name: 'Zenith Bank Plc',
          account_name: 'Kenny',
          account_number: '2343114543',
          default: true,
        },
        {
          bank_name: 'Union Bank of Nigeria Plc',
          account_name: 'Kenny',
          account_number: '2343234511',
          default: true,
        },
      ],
      ContactAddressJson: [
        {
          address1: '24 jude Street',
          address2: '2 Roseline',
          city: 'Apapa',
          state: 'Lagos',
          default: true,
        },
        {
          address1: '2 Ifunaya Street',
          address2: '4 Roseline',
          city: 'Apapa',
          state: 'Lagos',
          default: true,
        },
        {
          address1: '12 Asokoro Street',
          address2: '1 Roseline',
          city: 'Apapa',
          state: 'Lagos',
          default: true,
        },
      ],
      EmailAddressJson: [
        {
          email: 'Joy23e@yahoo.co.uk',
          default: true,
        },
        {
          email: 'Joy2e@yahoo.co.uk',
          default: true,
        },
        {
          email: 'Joy3@yahoo.co.uk',
          default: true,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '+2348045612354',
          default: true,
        },
        {
          phone: '+2348032312343',
          default: true,
        },
        {
          phone: '+2348076764454',
          default: true,
        },
      ],
      NOKFirstName: 'Gabriel',
      NOKLastName: 'Ikemefuna',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: '+2348043254389',
      NOKEmailAddress: 'Joy@yahoo.co.uk',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentNote: 'NULL',
      DocumentPath: {
        IdentificationProof:
          'public/files/identities/12345698991/12345698991_IdentificationProof.jpg',
        Photograph: 'public/files/identities/12345698991/12345698991_Photograph.jpg',
        ProofofResidence:
          'public/files/identities/12345698991/12345698991_ProofofResidence.jpg',
        Signature: 'public/files/identities/12345698991/12345698991_Signature.png',
      },
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: '234',
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '35',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '36',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '37',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '38',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '39',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '41',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '42',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '43',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '44',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '45',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '46',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '47',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '48',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '49',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '50',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
    {
      PersonMasterID: '51',
      Title: 'Bar',
      FirstName: 'Chemon',
      MiddleName: '',
      LastName: 'Gipsy',
      DateofBirth: '1991-01-02',
      NIN: '987656929809',
      BVN: '11271442245',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      BankDetailJson: null,
      ContactAddressJson: null,
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: null,
      AccountManagerNumber: null,
      DocumentNote: null,
      DocumentPath: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Becky Alpha',
      CustomerNumber: null,
      EmployerDetails: {
        EmployerName: 'Jordan Wislon',
        EmployerAddress: '2 Broad Street LV',
        EmployerNationality: 'United State',
        EmployerCity: 'Las Vegas',
        EmployerState: 'Nevada',
        EmployerZipCode: '199201',
      },
      NextofKinDetails: {
        NOKAddress: '22 Stratchan Street',
        NOKCity: 'Obalende',
        NOKState: 'Lagos',
        NOKZipCode: '100111',
      },
    },
  ],
  pendingApprovalCustomers: [
    {
      PersonMasterDraftID: '459',
      PersonMasterID: '27',
      Title: 'Dr',
      FirstName: 'Gabriel',
      MiddleName: 'Omokpo',
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: 'NULL',
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: '1',
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: '0',
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: 'true',
        },
        {
          email: 'omokpogabriel@gmail2.com',
          default: '0',
        },
        {
          email: 'gabbyo@gmail.com',
          default: '0',
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: '1',
        },
        {
          phone: '09013245673',
          default: '0',
        },
      ],
      NOKFirstName: 'Gabriel',
      NOKLastName: 'Ikemefuna',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: '09312311213',
      NOKEmailAddress: 'ndubisiomokpo@gmail.com',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        IdentificationProof:
          'public/files/kyc/approved/b8441580-0c61-4d53-96cb-3d455a5f647c/b8441580-0c61-4d53-96cb-3d455a5f647c_IdentificationProof.png',
        Photograph:
          'public/files/kyc/approved/b8441580-0c61-4d53-96cb-3d455a5f647c/b8441580-0c61-4d53-96cb-3d455a5f647c_Photograph.png',
        ProofofResidence:
          'public/files/kyc/approved/b8441580-0c61-4d53-96cb-3d455a5f647c/b8441580-0c61-4d53-96cb-3d455a5f647c_ProofofResidence.png',
        Signature:
          'public/files/kyc/approved/b8441580-0c61-4d53-96cb-3d455a5f647c/b8441580-0c61-4d53-96cb-3d455a5f647c_Signature.png',
      },
      DocumentNote: 'NULL',
      DocumentUID: 'b8441580-0c61-4d53-96cb-3d455a5f647c',
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '468',
      PersonMasterID: null,
      Title: 'NULL',
      FirstName: 'Gabriel',
      MiddleName: 'NULL',
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: 'NULL',
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: 'NULL',
      NOKLastName: 'NULL',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: 'NULL',
      NOKEmailAddress: 'NULL',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        IdentificationProof:
          'public/files/kyc/approved/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14_IdentificationProof.png',
        Photograph:
          'public/files/kyc/approved/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14_Photograph.png',
        ProofofResidence:
          'public/files/kyc/approved/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14_ProofofResidence.png',
        Signature:
          'public/files/kyc/approved/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14/f8c4bc76-4f41-493d-9b7e-ae11d2ceac14_Signature.png',
      },
      DocumentNote: 'NULL',
      DocumentUID: 'f8c4bc76-4f41-493d-9b7e-ae11d2ceac14',
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '469',
      PersonMasterID: null,
      Title: 'NULL',
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: 'NULL',
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: 'NULL',
      NOKLastName: 'NULL',
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: 'NULL',
      NOKEmailAddress: 'NULL',
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        IdentificationProof:
          'public/files/kyc/approved/46eb84fc-c50d-444b-96dc-14b94df5dc6d/46eb84fc-c50d-444b-96dc-14b94df5dc6d_IdentificationProof.png',
        Photograph:
          'public/files/kyc/approved/46eb84fc-c50d-444b-96dc-14b94df5dc6d/46eb84fc-c50d-444b-96dc-14b94df5dc6d_Photograph.png',
        ProofofResidence:
          'public/files/kyc/approved/46eb84fc-c50d-444b-96dc-14b94df5dc6d/46eb84fc-c50d-444b-96dc-14b94df5dc6d_ProofofResidence.png',
        Signature:
          'public/files/kyc/approved/46eb84fc-c50d-444b-96dc-14b94df5dc6d/46eb84fc-c50d-444b-96dc-14b94df5dc6d_Signature.png',
      },
      DocumentNote: 'NULL',
      DocumentUID: '46eb84fc-c50d-444b-96dc-14b94df5dc6d',
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '470',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        IdentificationProof:
          'public/files/kyc/approved/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4_IdentificationProof.png',
        Photograph:
          'public/files/kyc/approved/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4_Photograph.png',
        ProofofResidence:
          'public/files/kyc/approved/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4_ProofofResidence.png',
        Signature:
          'public/files/kyc/approved/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4/0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4_Signature.png',
      },
      DocumentNote: null,
      DocumentUID: '0a58b78b-68ee-4e1f-a5c2-27d97c6c95d4',
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '475',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: 1,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/831f5a83-ca03-4c4f-8758-a12a4693a774/831f5a83-ca03-4c4f-8758-a12a4693a774_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '831f5a83-ca03-4c4f-8758-a12a4693a774',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '477',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: null,
      DocumentNote: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '478',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: null,
      DocumentNote: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '479',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: null,
      DocumentNote: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '486',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah ',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/7ed13c3a-2c77-43b7-97a3-6d16ce4a6b7e/7ed13c3a-2c77-43b7-97a3-6d16ce4a6b7e_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '7ed13c3a-2c77-43b7-97a3-6d16ce4a6b7e',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '487',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah ',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/fa214042-17dd-4d42-8466-828f81285448/fa214042-17dd-4d42-8466-828f81285448_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: 'fa214042-17dd-4d42-8466-828f81285448',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '488',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: null,
      DocumentNote: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '490',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah ',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/9fb8370d-4135-4b30-9e68-96645a26f684/9fb8370d-4135-4b30-9e68-96645a26f684_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '9fb8370d-4135-4b30-9e68-96645a26f684',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '491',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah ',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/024634de-18f8-4a40-b1a2-a8d3577a4509/024634de-18f8-4a40-b1a2-a8d3577a4509_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '024634de-18f8-4a40-b1a2-a8d3577a4509',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '492',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/d8cd89c1-600e-4de2-bf4e-9ef2f54e7fb8/d8cd89c1-600e-4de2-bf4e-9ef2f54e7fb8_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: 'd8cd89c1-600e-4de2-bf4e-9ef2f54e7fb8',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '493',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/b13c6a66-5bc5-40d7-bff1-dc6d1990bdda/b13c6a66-5bc5-40d7-bff1-dc6d1990bdda_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: 'b13c6a66-5bc5-40d7-bff1-dc6d1990bdda',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '497',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/bb57feca-8600-4e45-8378-f6a7f55b0c90/bb57feca-8600-4e45-8378-f6a7f55b0c90_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: 'bb57feca-8600-4e45-8378-f6a7f55b0c90',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '498',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/6b1028c2-3692-47ab-910f-3ba17f71be3b/6b1028c2-3692-47ab-910f-3ba17f71be3b_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '6b1028c2-3692-47ab-910f-3ba17f71be3b',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '499',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/8d430a3f-6f49-4693-b46f-b034735d5dc1/8d430a3f-6f49-4693-b46f-b034735d5dc1_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '8d430a3f-6f49-4693-b46f-b034735d5dc1',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '500',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Gabriel',
      MiddleName: null,
      LastName: 'Ikemefuna',
      DateofBirth: '1991-01-05',
      NIN: null,
      BVN: '22171442291',
      Gender: 'ma',
      Nationality: 'Nigeria',
      CountryofOrigin: 'Nigeria',
      StateofOrigin: 'Lagos',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '12 Egbed Road',
          address2: 'Alimosho',
          city: 'Alimosho',
          state: 'Lagos',
          default: true,
          lga: 'Mainland',
        },
        {
          address1: '1 Ponle Close',
          address2: 'Akowonjo',
          city: 'Egbeda',
          state: 'Lagos',
          lga: 'Eti Osa',
          default: false,
        },
      ],
      EmailAddressJson: [
        {
          email: 'gabrielo@gmail.com',
          default: true,
        },
        {
          email: 'omokpogabriel@gmail.com',
          default: false,
        },
        {
          email: 'gabbyo@gmail.com',
          default: false,
        },
      ],
      PhoneNumberJson: [
        {
          phone: '09013145672',
          default: true,
        },
        {
          phone: '09013245673',
          default: false,
        },
      ],
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: null,
      DocumentNote: null,
      DocumentUID: null,
      MaritalStatus: 'married',
      MotherMaidenName: 'Risikat Omokpo',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
    {
      PersonMasterDraftID: '501',
      PersonMasterID: null,
      Title: null,
      FirstName: 'Oluwaseun',
      MiddleName: null,
      LastName: 'Ajayi',
      DateofBirth: null,
      NIN: null,
      BVN: '10095845387',
      Gender: 'ma',
      Nationality: null,
      CountryofOrigin: null,
      StateofOrigin: 'delta',
      IsSubmitted: '0',
      BankDetailJson: null,
      ContactAddressJson: [
        {
          address1: '9 ajah 00343',
          default: true,
          city: 'ajah',
          state: 'lagos',
        },
      ],
      EmailAddressJson: null,
      PhoneNumberJson: null,
      NOKFirstName: null,
      NOKLastName: null,
      RelationshipType: null,
      Comment: null,
      NOKPhoneNumber: null,
      NOKEmailAddress: null,
      CustomerTypeName: 'Individual',
      AccountManagerNumber: null,
      DocumentPath: {
        Photograph:
          'public/files/kyc/approved/93eedbb9-5877-4b36-b883-2fd29afeec2e/93eedbb9-5877-4b36-b883-2fd29afeec2e_Photograph.jpg',
      },
      DocumentNote: null,
      DocumentUID: '93eedbb9-5877-4b36-b883-2fd29afeec2e',
      MaritalStatus: 'single',
      MotherMaidenName: 'ajayi',
      EmployerDetails: null,
      NextofKinDetails: null,
    },
  ],
  draftCustomers: []
};
