import { Action } from '@ngrx/store';
import { Group } from 'app/core/models';

export enum GroupActionTypes {
  LoadGroups = '[Groups] Load Groups',
  LoadGroupsSuccess = '[Groups] Load Groups Success',
  LoadGroupsFail = '[Groups] Load Groups Fail',

  LoadGroup = '[Group] Load Group',

  ClearGroup = '[Group] Clear Group',
}

export class LoadGroups implements Action {
  readonly type = GroupActionTypes.LoadGroups;
}

export class LoadGroupsSuccess implements Action {
  readonly type = GroupActionTypes.LoadGroupsSuccess;
  constructor(public payload: Group[]) {}
}

export class LoadGroupsFail implements Action {
  readonly type = GroupActionTypes.LoadGroupsFail;
  constructor(public payload: any) {}
}

export class LoadGroup implements Action {
  readonly type = GroupActionTypes.LoadGroup;
  constructor(public payload: Group) {}
}

export class ClearGroup implements Action {
  readonly type = GroupActionTypes.ClearGroup;
}

export type GroupActions =
  | LoadGroups
  | LoadGroupsSuccess
  | LoadGroupsFail
  | LoadGroup
  | ClearGroup;
