import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/service/auth.service';
import { AuthUtils } from 'app/core/utils/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    // Todo: Check for access token and not expired to return header with bearer and auth

    // Response
    return next.handle(newReq).pipe(
      catchError((err) => {
        let errorObj: any = { success: false, status: null };
        console.log(err)

        // Catch "401 Unauthorized" responses
        if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
          // Sign out
          this._authService.signOut();

          errorObj = { ...errorObj, status: err.status, message: err.error.message };

          // Reload the app
          setTimeout(() => location.reload(), 3000);
        } else {
          if (err.error instanceof ErrorEvent) {
            errorObj = { ...errorObj, message: err.error.message };
          } else {
            if (err && err.status === 0) {
              errorObj = {
                success: false,
                message: 'Something is wrong. Please check your internet connection and try again',
                status: err.status,
              };
            } else {
              if (err.error.errors) {
                const err_arr = err.error.errors;
                errorObj = { ...errorObj, status: err.status, message: err_arr[Object.keys(err_arr)[0]][0] };
              } else {
                errorObj = { ...errorObj, status: err.status, message: err.error.message };
              }
            }
          }
        }
        return throwError(() => ({ ...err, errorObj }));
      }),
    );
  }
}
