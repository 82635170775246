import { Merchant } from 'app/core/models';

export interface MerchantData {
  isLoading: boolean;
  error: Error;
  approvedmerchants: Merchant[];
  pendingmerchants: Merchant[];
  selectedmerchant: Merchant[];
  indraftmerchants: Merchant[];
  rejectionReasons: string[];
  accountStatus: string[];
}

export interface MerchantState {
  readonly merchant: MerchantData
}

export const initialMerchantState: MerchantData = {
  isLoading: false,
  error: null,
  approvedmerchants: null,
  pendingmerchants: null,
  indraftmerchants: null,
  selectedmerchant: null,
  rejectionReasons: null,
  accountStatus: null,
}
