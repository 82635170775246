<!-- <div [formGroup]="inputdata.form">
  <div [ngSwitch]="inputdata.formfieldinfo.controlType">

    <div *ngSwitchCase="'dropdown'">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-label [attr.for]="inputdata.formfieldinfo.key">{{ inputdata.formfieldinfo.label }}</mat-label>
        <mat-select [formControlName]="inputdata.formfieldinfo.key" [id]="inputdata.formfieldinfo.key"
          [required]="inputdata.formfieldinfo.required">
          <mat-option *ngFor="let opt of inputdata.formfieldinfo.options" [value]="opt.key">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="(inputControl.touched && inputControl.invalid) || (inputdata.formparent && inputdata.formparent.submitted && inputControl.invalid)">
          <span *ngIf="inputControl.errors.required">{{ inputdata.formfieldinfo.label }} is required</span>
        </mat-error>

      </mat-form-field>
    </div>

    <div *ngSwitchCase="'textbox'">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-label [attr.for]="inputdata.formfieldinfo.key">{{ inputdata.formfieldinfo.label }}</mat-label>
        <input matInput type="text" [formControlName]="inputdata.formfieldinfo.key" [id]="inputdata.formfieldinfo.key"
          [required]="inputdata.formfieldinfo.required">
        <mat-error
          *ngIf="(inputControl.touched && inputControl.invalid) || (inputdata.formparent && inputdata.formparent.submitted && inputControl.invalid)">
          <span *ngIf="inputControl.errors.required">{{ inputdata.formfieldinfo.label }} is required</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div> -->

<div [formGroup]="inputdata.form">
  <div [ngSwitch]="inputdata.formfieldinfo.controlType">

    <div *ngSwitchCase="'dropdown'">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-label [attr.for]="inputdata.formfieldinfo.key">{{ inputdata.formfieldinfo.label }}</mat-label>
        <mat-select [formControlName]="inputdata.formfieldinfo.key" [id]="inputdata.formfieldinfo.key"
          [required]="inputdata.formfieldinfo.required" (selectionChange)="selectionChanged($event)">
          <mat-option *ngFor="let opt of inputdata.formfieldinfo.options" [value]="opt.key">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="(inputControl.touched && inputControl.invalid) || (inputdata.formparent && inputdata.formparent.submitted && inputControl.invalid)">
          <span *ngIf="inputControl.errors.required">{{ inputdata.formfieldinfo.label }} is required</span>
        </mat-error>

      </mat-form-field>
    </div>

    <div *ngSwitchCase="'textbox'">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-label [attr.for]="inputdata.formfieldinfo.key">{{ inputdata.formfieldinfo.label }}</mat-label>
        <input matInput type="text" [formControlName]="inputdata.formfieldinfo.key" [id]="inputdata.formfieldinfo.key"
          [required]="inputdata.formfieldinfo.required">
        <mat-error
          *ngIf="(inputControl.touched && inputControl.invalid) || (inputdata.formparent && inputdata.formparent.submitted && inputControl.invalid)">
          <span *ngIf="inputControl.errors.required">{{ inputdata.formfieldinfo.label }} is required</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
