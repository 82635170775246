import { Address, Email, Phone } from '.';

export interface AccountOfficer {
  ID: string;
  OfficerID: string;
  FirstName: string;
  LastName: string;
  Name: string;
  Phone: string;
  Email: string;
  CreatedBy: string;
  DateCreated: string;
  UpdatedBy: string;
  DateUpdated: string;
}

export interface AccountOfficer2 {
  PersonMasterID: string;
  ManagerNumber: string;
  ManagerName: string;
  ResidentialAddress: string;
  MailingAddress: Address[];
  EmailAddress: Email[];
  PhoneNumber: Phone[];
}

export interface AccountOfficer3 {
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateofBirth: string;
  Gender: string;
  DocumentPath: string;
  DocumentNote: string;
  DocumentUID: string;
  PersonMasterID: string;
  ManagerNumber: string;
  ManagerName: string;
  ResidentialAddress: string;
  MailingAddress: Address[];
  EmailAddress: Email[];
  PhoneNumber: Phone[];
}
