import { UserActions, UserActionTypes } from './user.actions';
import { initialUserState, UserData } from './user.state';

export const userReducer = (state = initialUserState, action: UserActions): UserData => {
  switch (action.type) {
    case UserActionTypes.LoadUsers: {
      return { ...state, isLoading: true, error: null };
    }

    case UserActionTypes.LoadUsersSuccess: {
      return { ...state, users: action.payload, isLoading: false, error: null };
    }

    case UserActionTypes.LoadUsersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case UserActionTypes.LoadUser: {
      return { ...state, isLoading: true, error: null };
    }

    case UserActionTypes.LoadUserSuccess: {
      return { ...state, user: action.payload, isLoading: false, error: null };
    }

    case UserActionTypes.LoadUserFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case UserActionTypes.ClearUser: {
      return { ...state, user: null };
    }

    default: {
      return state;
    }
  }
}
