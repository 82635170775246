import { Action } from '@ngrx/store';
import { ModuleResp } from 'app/core/models';

export enum ModuleActionTypes {
  LoadModules = '[Modules] Load Modules',
  LoadModulesSuccess = '[Modules] Load Modules Success',
  LoadModulesFail = '[Modules] Load Modules Fail',

  LoadModule = '[Module] Load Module',

  ClearModule = '[Module] Clear Module',
}

export class LoadModules implements Action {
  readonly type = ModuleActionTypes.LoadModules;
}

export class LoadModulesSuccess implements Action {
  readonly type = ModuleActionTypes.LoadModulesSuccess;
  constructor(public payload: any) {}
}

export class LoadModulesFail implements Action {
  readonly type = ModuleActionTypes.LoadModulesFail;
  constructor(public payload: any) {}
}

export class LoadModule implements Action {
  readonly type = ModuleActionTypes.LoadModule;
  constructor(public payload: ModuleResp) {}
}

export class ClearModule implements Action {
  readonly type = ModuleActionTypes.ClearModule;
}

export type ModuleActions =
  | LoadModules
  | LoadModulesSuccess
  | LoadModulesFail
  | LoadModule
  | ClearModule;
