import { createSelector } from '@ngrx/store';
import { GeneralData } from '.';
import { AppState } from '../root-state';

const getGeneralState = (state: AppState): GeneralData => state.general;

export const getGeneralLoading = createSelector(
  getGeneralState,
  state => state.isLoading,
);

export const getGeneralError = createSelector(getGeneralState, state => state.error);

export const getDocumentTypes = createSelector(
  getGeneralState,
  state => state.documenttypes,
);
export const getMaritalStatus = createSelector(
  getGeneralState,
  state => state.maritalstatus,
);
export const getGenders = createSelector(
  getGeneralState,
  state => state.gender,
);
export const getCountries = createSelector(
  getGeneralState,
  state => state.country,
);
export const getBanks = createSelector(
  getGeneralState,
  state => state.banks,
);
