import { Group } from 'app/core/models';

export interface GroupData {
  isLoading: boolean;
  error: Error;
  groups: Group[];
  group: Group;
}

export interface GroupState {
  readonly group: GroupData
}

export const initialGroupState: GroupData = {
  isLoading: false,
  error: null,
  groups: null,
  group: null,
}
