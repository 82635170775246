import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputComponent } from './component/forms/form-input/form-input.component';
import { LogoComponent } from './component/logo/logo.component';
import { WelcomeColumnComponent } from './component/welcome-column/welcome-column.component';
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { MatTableComponent } from './component/mat-table/mat-table.component';
import { MatTablePaginatedComponent } from './component/mat-table-paginated/mat-table-paginated.component';
import { DatatableComponent } from './component/datatable/datatable.component';
import { DialogComponent } from './component/dialog/dialog.component';
import { PermissionCheckDirective } from './directive/permission-check.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
      positionClass: 'toast-top-center',
    }),
    MaterialModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    WelcomeColumnComponent,
    FormInputComponent,
    LogoComponent,
    MatTableComponent,
    DatatableComponent,
    PermissionCheckDirective,
  ],
  declarations: [
    WelcomeColumnComponent,
    FormInputComponent,
    LogoComponent,
    MatTableComponent,
    MatTablePaginatedComponent,
    DatatableComponent,
    DialogComponent,
    PermissionCheckDirective,
  ],
})
export class SharedModule {}
