import { NavigationActionTypes, NavigationActions } from './navigation.actions';
import { NavigationData, initialNavigationState } from './navigation.state';

export const navigationReducer = (
  state = initialNavigationState,
  action: NavigationActions,
): NavigationData => {
  switch (action.type) {
    case NavigationActionTypes.LoadNavigations: {
      return { ...state, isLoading: true };
    }

    case NavigationActionTypes.LoadNavigationsSuccess: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        message: action.payload.message,
        permissions: action.payload.data.permissions,
        modules: action.payload.data.access
      };
    }

    case NavigationActionTypes.LoadNavigationsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case NavigationActionTypes.LoadNavigationMessage: {
      return { ...state, message: action.payload };
    }

    case NavigationActionTypes.LoadSelectedModule: {
      return { ...state, selectedModule: action.payload };
    }

    case NavigationActionTypes.StopNavigationLoading: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};
