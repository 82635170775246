export interface Bank {
  bank_name: string;
  account_name: string;
  account_number: string;
  default: boolean;
}

export interface BankList {
  id: number,
  name: string,
  slug?: string,
  code: string,
  longcode?: string,
  gateway?: string,
  pay_with_bank?: string,
  active: boolean,
  country?: string,
  currency?: string,
  type?: string,
  is_deleted?: boolean,
  createdAt?: string,
  updatedAt?: string,
}
