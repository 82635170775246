import { User } from 'app/core/models';

export interface UserData {
  isLoading: boolean;
  error: Error;
  users: User[];
  user: User;
}

export interface UserState {
  readonly user: UserData
}

export const initialUserState: UserData = {
  isLoading: false,
  error: null,
  users: null,
  user: null,
}
