import { SectionActions, SectionActionTypes } from './section.actions';
import { initialSectionState, SectionData } from './section.state';

export const sectionReducer = (state = initialSectionState, action: SectionActions): SectionData => {
  switch (action.type) {
    case SectionActionTypes.LoadSections: {
      return { ...state, isLoading: true, error: null };
    }

    case SectionActionTypes.LoadSectionsSuccess: {
      return { ...state, sections: action.payload, isLoading: false, error: null };
    }

    case SectionActionTypes.LoadSectionsFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case SectionActionTypes.LoadSection: {
      return { ...state, section: action.payload };
    }

    case SectionActionTypes.ClearSection: {
      return { ...state, sections: null, section: null };
    }

    default: {
      return state;
    }
  }
}
