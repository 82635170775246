import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public tableData: DataTableInfo;
  @Output() buttonClick = new EventEmitter();
  @Output() sideButtonClick = new EventEmitter();

  displayedColumns: string[];
  columnsToDisplay: string[];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.tableData.header;
    this.columnsToDisplay = this.displayedColumns.slice();
    this.dataSource.data = this.tableData.data;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tableData: DataTableInfo = changes.tableData.currentValue;
    this.tableData = tableData;
    this.displayedColumns = this.tableData.header;
    this.columnsToDisplay = this.displayedColumns.slice();
    this.dataSource.data = this.tableData.data;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clickEvent(id): void {
    this.buttonClick.emit(id);
  }

  sideButtonclickEvent(): void {
    this.sideButtonClick.emit();
  }

  isNumber(val): boolean {
    return !isNaN(val);
  }

  isString(val): boolean {
    return typeof val === 'string';
  }

  isNegativeNumber(val): boolean {
    return this.isNumber(val) && val < 0 && typeof val === 'number';
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}

export interface DataTableInfo {
  table_name?: string;
  header: string[];
  data: any[];
  filter?: TableFilter;
  loading?: boolean;
  description?: string;
  not_paginated?: boolean;
  side_top_button?: boolean;
  side_top_button_text?: string;
  side_top_button_permission?: string;
}

export interface TableFilter {
  filter_label: string;
  filter_placeholder: string;
}
