import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CustomerService } from 'app/core/service/customer.service';
import { Observable, startWith, switchMap, map, catchError, of } from 'rxjs';
import * as generalActions from './general.actions';

@Injectable()
export class GeneralEffects {
  constructor(private _actions$: Actions, private _customerService: CustomerService) {}

  loadDocumentTypes$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<generalActions.LoadDocumentTypes>(
        generalActions.GeneralActionTypes.LoadDocumentTypes,
      ),
      switchMap(action =>
        this._customerService.getDocumentTypes(action.payload).pipe(
          map(items => new generalActions.LoadDocumentTypesSuccess(items)),
          catchError(error => of(new generalActions.LoadDocumentTypesFail(error))),
        ),
      ),
    ),
  );

  loadMaritalStatus$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<generalActions.LoadGenders>(generalActions.GeneralActionTypes.LoadGenders),
      switchMap(() =>
        this._customerService.getMaritalStatus().pipe(
          map(items => new generalActions.LoadMaritalStatusSuccess(items)),
          catchError(error => of(new generalActions.LoadMaritalStatusFail(error))),
        ),
      ),
    ),
  );

  loadGender$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<generalActions.LoadGenders>(generalActions.GeneralActionTypes.LoadGenders),
      switchMap(() =>
        this._customerService.getGenders().pipe(
          map(items => new generalActions.LoadGendersSuccess(items)),
          catchError(error => of(new generalActions.LoadGendersFail(error))),
        ),
      ),
    ),
  );

  loadCountry$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<generalActions.LoadCountries>(generalActions.GeneralActionTypes.LoadCountries),
      switchMap(() =>
        this._customerService.getCountryAndStates().pipe(
          map(items => new generalActions.LoadCountriesSuccess(items)),
          catchError(error => of(new generalActions.LoadCountriesFail(error))),
        ),
      ),
    ),
  );

  loadBankList$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<generalActions.LoadBankList>(generalActions.GeneralActionTypes.LoadBankList),
      switchMap(() =>
        this._customerService.getBankList().pipe(
          map(items => new generalActions.LoadBankListSuccess(items)),
          catchError(error => of(new generalActions.LoadBankListFail(error))),
        ),
      ),
    ),
  );
}
