<div>
  <!-- Background - @formatter:off -->
  <!-- Rings -->
  <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
    preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
    <g class="text-gray-200 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
      <circle r="234" cx="196" cy="23"></circle>
      <circle r="234" cx="790" cy="491"></circle>
    </g>
  </svg>
  <!-- Dots -->
  <svg class="absolute -top-16 -right-16 text-gray-200" viewBox="0 0 220 192" width="220" height="192" fill="none">
    <defs>
      <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
        patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
      </pattern>
    </defs>
    <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
  </svg>
  <!-- @formatter:on -->
  <!-- Content -->
  <div class="z-10 relative w-full max-w-2xl">
    <div class="text-7xl font-bold leading-none text-white">
      <div>Welcome to</div>
      <div>Phoenix Admin</div>
    </div>
    <div class="mt-6 text-lg tracking-tight leading-6 text-gray-200">
      Phoenix-admin helps users to manage and organized companies activities all from dashboards full of
      beautiful and rich modules.
      Join us and start managing customer and financial activities.
    </div>
    <div class="flex items-center mt-8">
      <div class="flex flex-0 items-center -space-x-1.5">
        <img class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-600 ring-offset-gray-800 object-cover"
          src="assets/images/avatars/female-18.jpg">
        <img class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-600 ring-offset-gray-800 object-cover"
          src="assets/images/avatars/female-11.jpg">
        <img class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-600 ring-offset-gray-800 object-cover"
          src="assets/images/avatars/male-09.jpg">
        <img class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-600 ring-offset-gray-800 object-cover"
          src="assets/images/avatars/male-16.jpg">
      </div>
      <div class="ml-4 font-medium tracking-tight text-gray-200">More than 17k people are currently using the platform, it's your
        turn</div>
    </div>
  </div>
</div>
