import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { getUserToken } from '../utils/auth.utils';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  baseUrl: string = environment.baseApiUrl;
  reportServer: string = environment.reportServer;
  phoenixWebBaseApiUrl: string = environment.phoenixWebBaseApiUrl;
  phoenixWebAccessKey: string = environment.phoenixWebAccessKey;
  phoenixWebAccessCode: string = environment.phoenixWebAccessCode;
  phoenixWebAppKey: string = environment.phoenixWebAppKey;

  constructor(private http: HttpClient) { }

  private generateFullURL(url: string): string {
    return `${this.baseUrl + url}`;
  }

  private generatePhoenixWebAPIURL(url: string): string {
    return `${this.phoenixWebBaseApiUrl + url}`;
  }

  private getAuthHeader(): HttpHeaders {
    const token = getUserToken();

    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return header;
  }

  private getPhoenixWebAuthHeader(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'channel': 'web',
      'access-key': `${this.phoenixWebAccessKey}`,
      'access-code': `${this.phoenixWebAccessCode}`,
    });

    return header;
  }

  private getAuthHeaderUploads(): HttpHeaders {
    const token = getUserToken();

    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    return header;
  }

  guestPost(url: string, obj: any): any {
    return this.http.post<any>(this.generateFullURL(url), obj);
  }

  guestGet(url: string): any {
    return this.http.get<any>(this.generateFullURL(url));
  }

  authGet(url: string): any {
    const httpOptions = {
      headers: this.getAuthHeader()
    };

    return this.http.get<any>(this.generateFullURL(url), httpOptions);
  }

  authPhoenixApiGet(url: string): any {
    const httpOptions = {
      headers: this.getPhoenixWebAuthHeader()
    };

    return this.http.get<any>(this.generatePhoenixWebAPIURL(url), httpOptions);
  }

  authPost(url: string, data: any, channel?: boolean): any {
    const httpOptions = {
      headers: this.getAuthHeader()
    };
    channel && httpOptions.headers.append('channel', 'web');
    return this.http.post<any>(this.generateFullURL(url), data, httpOptions);
  }

  authPostUploads(url: string, data: any): any {
    const httpOptions = {
      headers: this.getAuthHeaderUploads()
    };
    return this.http.post<any>(this.generateFullURL(url), data, httpOptions);
  }

  authGet2(url: string, params?: any): any {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((k) => {
        httpParams = httpParams.set(k, params[k]);
      });
    }
    const httpOptions = {
      headers: this.getAuthHeader(),
      params: httpParams
    };
    return this.http.get<any>(this.generateFullURL(url), httpOptions);
  }

  reportServerUrl(): string {
    return this.reportServer;
  }
}
