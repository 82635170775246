import { createSelector } from '@ngrx/store';
import { AppState } from '../root-state';
import { MenuData } from '.';

const getMenuState = (state: AppState): MenuData => state.menu;

export const getMenuLoading = createSelector(
  getMenuState,
  state => state.isLoading,
);

export const getMenuError = createSelector(getMenuState, state => state.error);

export const getMenus = createSelector(
  getMenuState,
  state => state.menus,
);

export const getMenu = createSelector(
  getMenuState,
  state => state.menu,
);
