import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ModuleService } from 'app/core/service/module.service';
import { Observable, switchMap, map, catchError, of } from 'rxjs';
import * as moduleActions from './module.actions';

@Injectable()
export class ModuleEffects {
  constructor(private _actions$: Actions, private _moduleService: ModuleService) {}

  loadModule$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<moduleActions.LoadModules>(
        moduleActions.ModuleActionTypes.LoadModules,
      ),
      switchMap(() =>
        this._moduleService.getModules().pipe(
          map(items => new moduleActions.LoadModulesSuccess(items)),
          catchError(error =>
            of(new moduleActions.LoadModulesFail(error)),
          ),
        ),
      ),
    ),
  );
}
