import { createSelector } from '@ngrx/store';
import { NavigationData } from '.';
import { AppState } from '../root-state';

const getNavigationState = (state: AppState): NavigationData => state.navigation;

export const getNavigationLoading = createSelector(getNavigationState, state => state.isLoading);

export const getNavigationSuccessMessage = createSelector(getNavigationState, state => state.message);

export const getNavigationError = createSelector(getNavigationState, state => state.error);

export const getNavigationModules = createSelector(getNavigationState, state => state.modules);

export const getNavigationPermissions = createSelector(getNavigationState, state => state.permissions);

export const getSelectedModule = createSelector(getNavigationState, state => state.selectedModule)
