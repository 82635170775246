import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormInputBase, FormInputData } from '../form-input-base';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() inputdata: FormInputData;
  @Output() selectionChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  get inputControl(): FormControl {
    return this.inputdata.form.controls[this.inputdata.formfieldinfo.key] as FormControl;
  }

  get isValid(): boolean {
    return this.inputdata.form.controls[this.inputdata.formfieldinfo.key].valid;
  }

  selectionChanged(event): void {
    this.selectionChange.emit(event);
  }

}
