import { AccountOfficerActions, AccountOfficerActionTypes } from './account-officer.actions';
import { AccountOfficerData, initialAccountOfficerState } from './account-officer.state';

export const accountOfficerReducer = (state = initialAccountOfficerState, action: AccountOfficerActions): AccountOfficerData => {
  switch (action.type) {
    case AccountOfficerActionTypes.LoadAccountOfficers: {
      return { ...state, isLoading: true, error: null };
    }

    case AccountOfficerActionTypes.LoadAccountOfficersSuccess: {
      return { ...state, accountofficers: action.payload, isLoading: false, error: null };
    }

    case AccountOfficerActionTypes.LoadAccountOfficersFail: {
      return { ...state, isLoading: false, error: action.payload };
    }

    default: {
      return state;
    }
  }
}
