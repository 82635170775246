export interface DocumentTypes {
  DocumentTypeName: string;
  DocumentTypeDescription: string;
  SysDocumentTypeCode: string;
}

export interface DocumentTypesReq {
  document_code: string;
  document_name: string;
}
