export interface Section {
  ID: string;
  SectionID: string;
  ModuleID: string;
  Name: string;
  Description: string;
  Sequence: string;
  Active: string;
  CreatedBy: string;
  UpdatedBy: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface SectionRequest {
  name: string;
  description: string;
  module_id: string;
  uuid?: string;
  active: number;
}

export interface SectionGroupRequest {
  section_uuid: string[];
  group_uuid: string;
}

export interface GroupSectionRequest {
  group_uuid: string[];
  section_uuid: string;
}
