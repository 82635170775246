<ng-container>
  <div class="p-6 flex items-center justify-between">
    <div>
      <div class="mr-4 text-1xl font-medium tracking-tight leading-6 truncate">{{ tableData.table_name }}</div>
      <div class="text-secondary font-medium text-sm">{{ tableData.description }}</div>
    </div>
    <div class="flex items-center">
      <button *ngIf="tableData.side_top_button" (click)="sideButtonclickEvent()" [appPermissionCheck]="tableData.side_top_button_permission" type="button" class="bg-orange-600 text-white text-sm rounded py-0.5 px-2 h-7 min-h-full">
        <span class="ml-2 mr-1 !text-white">{{ tableData.side_top_button_text }}</span>
      </button>
    </div>
  </div>
  <div class="overflow-x-auto mx-6">
    <table mat-table [dataSource]="dataSource" [trackBy]="trackByFn" class="w-full bg-transparent" matSort
      id="htmlData">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let colIndex = index">

        <th class="border-b bg-gray-100 text-xs xl:text-sm lg:text-sm" mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ column | titlecase }}</th>

        <td mat-cell *matCellDef="let element" class="text-xs xl:text-sm lg:text-sm">
          <span *ngIf="element[column] && (!element[column]['status'] || element[column]['status'] === undefined)">
            {{ element[column] }}
          </span>
          <span
            *ngIf="element[column] && element[column]['status'] !== undefined && element[column]['status'] === true">
            <button class="rounded bg-orange-600 py-1 px-4 text-white" (click)="clickEvent(element[column]['id'])" [appPermissionCheck]="element[column]['permission']">{{
              element[column]['text'] }}</button>
          </span>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="tableData.header.length">
          <span *ngIf="tableData.data.length === 0 && !tableData.loading">Data is currently empty.</span>
          <span *ngIf="tableData.loading && tableData.data.length === 0">Loading...</span>
          <span *ngIf="tableData.data.length !== 0 && !tableData.loading">No Data Match for
            "<b>{{ input.value }}</b>"</span>
        </td>
      </tr>
    </table>
    <mat-paginator *ngIf="!tableData.not_paginated" [pageSizeOptions]="[40, 50, 100]"></mat-paginator>
  </div>
</ng-container>
