import { Section } from 'app/core/models';

export interface SectionData {
  isLoading: boolean;
  error: Error;
  sections: any;
  section: Section;
}

export interface SectionState {
  readonly section: SectionData
}

export const initialSectionState: SectionData = {
  isLoading: false,
  error: null,
  sections: null,
  section: null,
}
