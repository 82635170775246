import { Action } from '@ngrx/store';
import { Customer, CustomerListParams } from 'app/core/models';

export enum CustomerActionTypes {
  LoadApprovedCustomers = '[Approved Customers] Load Approved Customers',
  LoadApprovedCustomersSuccess = '[Approved Customers] Load Approved Customers Success',
  LoadApprovedCustomersFail = '[Approved Customers] Load Approved Customers Fail',

  LoadPendingCustomers = '[Pending Customers] Load Pending Customers',
  LoadPendingCustomersSuccess = '[Pending Customers] Load Pending Customers Success',
  LoadPendingCustomersFail = '[Pending Customers] Load Pending Customers Fail',

  LoadInDraftCustomers = '[InDraft Customers] Load InDraft Customers',
  LoadInDraftCustomersSuccess = '[InDraft Customers] Load InDraft Customers Success',
  LoadInDraftCustomersFail = '[InDraft Customers] Load InDraft Customers Fail',

  LoadCustomer = '[Customer] Load Customer',
  LoadCustomerSuccess = '[Customer] Load Customer Success',
  LoadCustomerFail = '[Customer] Load Customer Fail',

  LoadCustomerRejectionReasons = '[Customer Rejection Reasons] Load Customer Rejection Reasons',
  LoadCustomerRejectionReasonsSuccess = '[Customer Rejection Reasons] Load Customer Rejection Reasons Success',
  LoadCustomerRejectionReasonsFail = '[Customer Rejection Reasons] Load Customer Rejection Reasons Fail',

  LoadCustomerAccountStatus = '[Customer Account Status] Load Customer Account Status',
  LoadCustomerAccountStatusSuccess = '[Customer Account Status] Load Customer Account Status Success',
  LoadCustomerAccountStatusFail = '[Customer Account Status] Load Customer Account Status Fail',

  ClearCustomer = '[Customer] Clear Customer',
}

export class LoadApprovedCustomers implements Action {
  readonly type = CustomerActionTypes.LoadApprovedCustomers;
  constructor(public payload: CustomerListParams) {}
}

export class LoadApprovedCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.LoadApprovedCustomersSuccess;
  constructor(public payload: Customer[]) {}
}

export class LoadApprovedCustomersFail implements Action {
  readonly type = CustomerActionTypes.LoadApprovedCustomersFail;
  constructor(public payload: any) {}
}

export class LoadPendingCustomers implements Action {
  readonly type = CustomerActionTypes.LoadPendingCustomers;
  constructor(public payload: CustomerListParams) {}
}

export class LoadPendingCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.LoadPendingCustomersSuccess;
  constructor(public payload: Customer[]) {}
}

export class LoadPendingCustomersFail implements Action {
  readonly type = CustomerActionTypes.LoadPendingCustomersFail;
  constructor(public payload: any) {}
}

export class LoadInDraftCustomers implements Action {
  readonly type = CustomerActionTypes.LoadInDraftCustomers;
  constructor(public payload: CustomerListParams) {}
}

export class LoadInDraftCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.LoadInDraftCustomersSuccess;
  constructor(public payload: Customer[]) {}
}

export class LoadInDraftCustomersFail implements Action {
  readonly type = CustomerActionTypes.LoadInDraftCustomersFail;
  constructor(public payload: any) {}
}

export class LoadCustomer implements Action {
  readonly type = CustomerActionTypes.LoadCustomer;
  constructor(public payload: CustomerListParams) {}
}

export class LoadCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.LoadCustomerSuccess;
  constructor(public payload: Customer[]) {}
}

export class LoadCustomerFail implements Action {
  readonly type = CustomerActionTypes.LoadCustomerFail;
  constructor(public payload: any) {}
}

export class LoadCustomerRejectionReasons implements Action {
  readonly type = CustomerActionTypes.LoadCustomerRejectionReasons;
}

export class LoadCustomerRejectionReasonsSuccess implements Action {
  readonly type = CustomerActionTypes.LoadCustomerRejectionReasonsSuccess;
  constructor(public payload: string[]) {}
}

export class LoadCustomerRejectionReasonsFail implements Action {
  readonly type = CustomerActionTypes.LoadCustomerRejectionReasonsFail;
  constructor(public payload: any) {}
}

export class LoadCustomerAccountStatus implements Action {
  readonly type = CustomerActionTypes.LoadCustomerAccountStatus;
}

export class LoadCustomerAccountStatusSuccess implements Action {
  readonly type = CustomerActionTypes.LoadCustomerAccountStatusSuccess;
  constructor(public payload: string[]) {}
}

export class LoadCustomerAccountStatusFail implements Action {
  readonly type = CustomerActionTypes.LoadCustomerAccountStatusFail;
  constructor(public payload: any) {}
}

export class ClearCustomer implements Action {
  readonly type = CustomerActionTypes.ClearCustomer;
}

export type CustomerActions =
  | LoadApprovedCustomers
  | LoadApprovedCustomersSuccess
  | LoadApprovedCustomersFail
  | LoadPendingCustomers
  | LoadPendingCustomersSuccess
  | LoadPendingCustomersFail
  | LoadInDraftCustomers
  | LoadInDraftCustomersSuccess
  | LoadInDraftCustomersFail
  | LoadCustomer
  | LoadCustomerSuccess
  | LoadCustomerFail
  | LoadCustomerRejectionReasons
  | LoadCustomerRejectionReasonsSuccess
  | LoadCustomerRejectionReasonsFail
  | LoadCustomerAccountStatus
  | LoadCustomerAccountStatusSuccess
  | LoadCustomerAccountStatusFail
  | ClearCustomer;
