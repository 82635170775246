import { CountryRespData, DocumentTypes, Gender, BankList, MaritalStatus } from 'app/core/models';

export interface GeneralData {
  isLoading: boolean;
  error: Error;
  documenttypes: DocumentTypes[];
  maritalstatus: MaritalStatus[];
  gender: Gender[];
  banks: BankList[];
  country: CountryRespData[];
}

export interface GeneralState {
  readonly general: GeneralData;
}

export const initialGeneralState: GeneralData = {
  isLoading: false,
  error: null,
  documenttypes: null,
  maritalstatus: null,
  gender: null,
  banks: null,
  country: null,
};
