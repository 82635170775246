import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class MatTableComponent implements OnInit {
  @Input() tableColumns: MatTableColumn[] = [];
  @Input() tableData: any[] = [];
  @Input() tableInfo: TableInfo;


  displayedColumns: string[];
  dataSource = new MatTableDataSource();

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.tableColumns.map(column => column.columnDef);
    this.dataSource.data = this.tableData;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}

export interface MatTableColumn {
  columnDef: string;
  header: string;
  cell: Function;
  isLink?: boolean;
  url?: string;
}

export interface TableInfo {
  tableName: string;
  description: string;
}
