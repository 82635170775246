import {
  Address,
  Bank,
  DocumentPath,
  Email,
  Employer,
  NextOfKin,
  Phone,
  CommentDetails,
} from '.';

export interface Customer {
  PersonMasterDraftID?: string;
  PersonMasterID: string;
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateofBirth: string;
  NIN: string;
  BVN: string;
  Gender: string;
  Nationality: string;
  CountryofOrigin: string;
  StateofOrigin: string;
  BankDetailJson: Bank[];
  ContactAddressJson: Address[];
  EmailAddressJson: Email[];
  PhoneNumberJson: Phone[];
  NOKFirstName: string;
  NOKLastName: string;
  RelationshipType: string;
  Comment: string;
  NOKPhoneNumber: string;
  NOKEmailAddress: string;
  CustomerTypeName: string;
  AccountManagerNumber: string;
  DocumentNote: string;
  DocumentPath: DocumentPath[];
  DocumentUID: string;
  MaritalStatus: string;
  MotherMaidenName: string;
  CustomerNumber: string;
  EmployerDetails: Employer;
  NextofKinDetails: NextOfKin;
  CreatedDateTime: Date;
  LastChangedAt: Date;
}

export interface CustomerListParams {
  status?: string;
  is_submitted?: string;
  id?: string;
  page?: number;
  per_page?: number;
  bvn?: string;
  firstname?: string;
  lastname?: string;
  nin?: string;
  email?: string;
}

export interface CustomerResponseData {
  current_page: number;
  data: Customer[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: any[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface CustomerUpdateResponseData {
  current_page: number;
  data: CustomerUpdate;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: any[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface CountryReqParams {
  country?: string;
  state?: string;
}

export interface CountryRespData {
  CountryID: string;
  Country: string;
  CountryCode: string;
}

export interface CustomerUpdate {
  PersonMasterDraftID?: string;
  PersonMasterID?: string;
  Title?: string;
  Orig_Title?: string;
  FirstName?: string;
  Orig_FirstName?: string;
  MiddleName?: string;
  Orig_MiddleName?: string;
  LastName?: string;
  Orig_LastName?: string;
  DateofBirth?: string;
  Orig_DateofBirth?: string;
  NIN?: string;
  Orig_NIN?: string;
  BVN?: string;
  Orig_BVN?: string;
  Gender?: string;
  Orig_Gender?: string;
  Nationality?: string;
  Orig_Nationality?: string;
  CountryofOrigin?: string;
  Orig_CountryofOrigin?: string;
  StateofOrigin?: string;
  Orig_StateofOrigin?: string;
  BankDetailJson?: Bank[];
  Orig_BankDetail?: Bank[];
  ContactAddressJson?: Address[];
  Orig_ContactAddressJson?: Address[];
  EmailAddressJson?: Email[];
  Orig_EmailAddressJson?: Email[];
  PhoneNumberJson?: Phone[];
  Orig_PhoneNumberJson?: Phone[];
  NOKFirstName?: string;
  Orig_NOKFirstName?: string;
  NOKLastName?: string;
  Orig_NOKLastName?: string;
  RelationshipType?: string;
  Orig_RelationshipType?: string;
  Comment?: CommentDetails[];
  NOKPhoneNumber?: string;
  NOKEmailAddress?: string;
  CustomerTypeName?: string;
  Orig_CustomerTypeName?: string;
  AccountManagerNumber?: string;
  DocumentNote?: string;
  Orig_DocumentNote?: any;
  DocumentPath?: DocumentPath[];
  Orig_DocumentPath?: DocumentPath[];
  DocumentUID?: string;
  Orig_DocumentUID?: string;
  MaritalStatus?: string;
  Orig_MaritalStatus?: string;
  MotherMaidenName?: string;
  Orig_MotherMaidenName?: string;
  CustomerNumber?: string;
  EmployerDetails?: Employer;
  Orig_EmployerDetails?: Employer;
  NextofKinDetails?: NextOfKin;
  Orig_NextofKinDetails?: NextOfKin;
  FundSource?: any;
  Orig_FundSource?: any;
  AnnualIncome?: any;
  Orig_AnnualIncome?: any;
  RiskTolerance?: any;
  Orig_RiskTolerance?: any;
  InvestmentExperience?: any;
  Orig_InvestmentExperience?: any;
  InvestmentObjective?: any;
  Orig_InvestmentObjective?: any;
  PersonMasterDraftReference?: string;
  BVNCheckResult?: any;
  NINCheckResult?: any;
  IsSubmitted?: string;
  AccountStatus?: string;
  RejectionReason?: string[];
  IsRejected?: string;
}

