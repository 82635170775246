import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, tap, map, throwError, of } from 'rxjs';
import { UserGroupRequest, UUID } from '../models';
import { User, UserReq } from '../models/user';
import { HttpClientService } from './http-client.service';
import { AppStorage } from '../utils/storage';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  constructor(private _httpClient: HttpClient, private httpService: HttpClientService) {}

  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get(): Observable<User> {
    const currentUser = AppStorage.get('currentUser');
    return this._httpClient.get<User>('api/common/user').pipe(
      tap((user) => {
        const user_ = { ...user, email: currentUser.Email, name: currentUser.FullName}
        this._user.next(user_);
      }),
    );
  }

  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }

  getUsers(params?: any): Observable<any> {
    return this.httpService.authGet2('users', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getUser(params: UUID): Observable<User> {
    return this.httpService.authGet2('users/show', params).pipe(
      map((response: any) => {
        if (response.status && response.code === 200) {
          return response.data;
        }
        return throwError(() => response);
      }),
    );
  }

  getUserGroups(params: UUID): Observable<any> {
    return this.httpService.authGet2('users/groups/show-user-groups', params);
  }

  postUserCreation(params: UserReq): Observable<any> {
    return this.httpService.authPost('users/create', params);
  }

  postPasswordReset(params: { username: string; new_password: string }): Observable<any> {
    return this.httpService.authPost('users/password/reset', params);
  }

  postPasswordChange(params: {
    old_password: string;
    new_password: string;
  }): Observable<any> {
    return this.httpService.authPost('users/password/change', params);
  }

  postUsersToGroup(params: UserGroupRequest): Observable<any> {
    return this.httpService.authPost('users/groups/add', params);
  }

  postUsersToGroupRemoval(params: UserGroupRequest): Observable<any> {
    return this.httpService.authPost('users/groups/remove', params);
  }

  postDisableUser(params: UUID): Observable<any> {
    return this.httpService.authPost('users/disable', params);
  }

  postEnableUser(params: UUID): Observable<any> {
    return this.httpService.authPost('users/enable', params);
  }

  postEnableDisableUser(params: { uuid: string; action: string }): Observable<any> {
    return this.httpService.authPost(`users/${params.action}`, { uuid: params.uuid });
  }

  postUserUpdate(params: UserReq): Observable<any> {
    return this.httpService.authPost('users/update', params);
  }

  postUserCreateUpdate(params: { user: UserReq; action: string }): Observable<any> {
    return this.httpService.authPost(`users/${params.action}`, params.user);
  }

  postUserSignOut(params: UUID): Observable<any> {
    return this.httpService.authPost('/users/logout-user', params);
  }

  getUserAccess(): Observable<any> {
    const navItems = AppStorage.get('NavItems');
    if (navItems) {
      return of(navItems.data.access);
    }
    return this.httpService.authGet('/user/access').pipe(
      tap(items => AppStorage.set('NavItems', items)),
      map((resp: any) => resp.data.access),
    );
  }

  getUserPermissions(): string[] {
    const navItems = AppStorage.get('NavItems');
    const user = AppStorage.get('currentUser');

    if (user && user.IsAdmin === '1') {
      return ['isAdmin'];
    }

    return navItems.data.permissions.map(permission => permission.Slug);
  }

  hasPermission(permission: string): boolean {
    const userPermissions = this.getUserPermissions();
    return userPermissions.includes(permission) || userPermissions.includes('isAdmin');
  }
}
