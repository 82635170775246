import { createSelector } from '@ngrx/store';
import { CustomerData } from '.';
import { AppState } from '../root-state';

const getCustomerState = (state: AppState): CustomerData => state.customer;

export const getCustomerLoading = createSelector(
  getCustomerState,
  state => state.isLoading,
);

export const getCustomerError = createSelector(getCustomerState, state => state.error);

export const getApprovedCustomers = createSelector(
  getCustomerState,
  state => state.approvedcustomers,
);
export const getPendingCustomers = createSelector(
  getCustomerState,
  state => state.pendingcustomers,
);
export const getInDraftCustomers = createSelector(
  getCustomerState,
  state => state.indraftcustomers,
);
export const getCustomer = createSelector(
  getCustomerState,
  state => state.selectedcustomer,
);
export const getCustomerRejectionReasons = createSelector(
  getCustomerState,
  state => state.rejectionReasons,
);
export const getCustomerAccountStatus = createSelector(
  getCustomerState,
  state => state.accountStatus,
);
