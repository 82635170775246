export interface Menu {
  ID: string;
  MenuID: string;
  Name: string;
  Description: string;
  SectionID: string;
  ParentID: string;
  Route: string;
  IconClass: string;
  Sequence: string;
  Active: string;
  CreatedBy: string;
  UpdatedBy: string;
  CreatedAt: string;
  UpdatedAt: string;
  pivot: {
    GroupID: string;
    MenuID: string;
  };
}

export interface MenuRequest {
  name: string;
  description: string;
  section_id: string;
  parent_id?: string;
  route?: string;
  active: number;
}

export interface MenuGroupRequest {
  menu_uuid: string[];
  group_uuid: string;
}
