import {
  NavigationMenus,
  NavigationModules,
  NavigationPermissions,
  NavigationSections,
} from 'app/core/models/navigation';
import { FuseNavigationItem } from '@fuse/components/navigation';

export interface NavigationData {
  isLoading: boolean;
  error: Error | undefined;
  message: string | null;
  permissions: NavigationPermissions[];
  menus: NavigationMenus[];
  sections: NavigationSections[];
  modules: NavigationModules[];
  selectedModule: FuseNavigationItem;
}

export interface NavigationState {
  readonly orderunit: NavigationData;
}

export const initialNavigationState: NavigationData = {
  isLoading: false,
  error: undefined,
  message: undefined,
  permissions: [],
  menus: [],
  sections: [],
  modules: [],
  selectedModule: undefined
};
