import { createSelector } from '@ngrx/store';
import { GroupData } from '.';
import { AppState } from '../root-state';

const getGroupState = (state: AppState): GroupData => state.group;

export const getGroupLoading = createSelector(
  getGroupState,
  state => state.isLoading,
);

export const getGroupError = createSelector(getGroupState, state => state.error);

export const getGroups = createSelector(
  getGroupState,
  state => state.groups,
);

export const getGroup = createSelector(
  getGroupState,
  state => state.group,
);
