import { Menu } from 'app/core/models';

export interface MenuData {
  isLoading: boolean;
  error: Error;
  menus: any;
  menu: Menu;
}

export interface MenuState {
  readonly menu: MenuData
}

export const initialMenuState: MenuData = {
  isLoading: false,
  error: null,
  menus: null,
  menu: null,
}
