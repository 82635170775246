import { Action } from '@ngrx/store';
import { Menu } from 'app/core/models';

export enum MenuActionTypes {
  LoadMenus = '[Menus] Load Menus',
  LoadMenusSuccess = '[Menus] Load Menus Success',
  LoadMenusFail = '[Menus] Load Menus Fail',

  LoadMenu = '[Menu] Load Menu',

  ClearMenu = '[Menu] Clear Menu',
}

export class LoadMenus implements Action {
  readonly type = MenuActionTypes.LoadMenus;
}

export class LoadMenusSuccess implements Action {
  readonly type = MenuActionTypes.LoadMenusSuccess;
  constructor(public payload: any) {}
}

export class LoadMenusFail implements Action {
  readonly type = MenuActionTypes.LoadMenusFail;
  constructor(public payload: any) {}
}

export class LoadMenu implements Action {
  readonly type = MenuActionTypes.LoadMenu;
  constructor(public payload: Menu) {}
}

export class ClearMenu implements Action {
  readonly type = MenuActionTypes.ClearMenu;
}

export type MenuActions =
  | LoadMenus
  | LoadMenusSuccess
  | LoadMenusFail
  | LoadMenu
  | ClearMenu;
