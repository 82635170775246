import { Action } from '@ngrx/store';
import { User, UUID } from 'app/core/models';

export enum UserActionTypes {
  LoadUsers = '[Users] Load  Users',
  LoadUsersSuccess = '[Users] Load Users Success',
  LoadUsersFail = '[Users] Load Users Fail',

  LoadUser = '[Users] Load  User',
  LoadUserSuccess = '[User] Load User Success',
  LoadUserFail = '[User] Load User Fail',

  ClearUser = '[User] Clear User',
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;
}

export class LoadUsersSuccess implements Action {
  readonly type = UserActionTypes.LoadUsersSuccess;
  constructor(public payload: User[]) {}
}

export class LoadUsersFail implements Action {
  readonly type = UserActionTypes.LoadUsersFail;
  constructor(public payload: any) {}
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;
  constructor(public payload: UUID) {}
}

export class LoadUserSuccess implements Action {
  readonly type = UserActionTypes.LoadUserSuccess;
  constructor(public payload: User) {}
}

export class LoadUserFail implements Action {
  readonly type = UserActionTypes.LoadUserFail;
  constructor(public payload: any) {}
}

export class ClearUser implements Action {
  readonly type = UserActionTypes.ClearUser;
}

export type UserActions =
  | LoadUsers
  | LoadUsersSuccess
  | LoadUsersFail
  | LoadUser
  | LoadUserSuccess
  | LoadUserFail
  | ClearUser;
