import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, combineLatest, map, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../service/user.service';
import { AppStorage } from '../utils/storage';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    const navData$ = this._httpClient.get<Navigation>('api/common/navigation');
    const userNavData$ = this.userService.getUserAccess();

    const user = AppStorage.get('currentUser');
    if (user && user.IsAdmin === '1') {
      return navData$.pipe(
        tap((navigation) => {
          this._navigation.next(navigation);
        }),
      );
    }

    const combinedNav$ =  combineLatest(([navData$, userNavData$])).pipe(
      map(([nav, userNav]) => {
        const names = userNav.map(uNav => uNav.Name)
        nav.default[0].children = nav.default[0].children.filter(child => names.includes(child.title));
        return nav;
      }),
    )




    return combinedNav$.pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      }),
    );
  }
}
